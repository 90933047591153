import React from "react";
import { CaretDown, Check, Star } from "@phosphor-icons/react";
import clsx from "clsx";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { Button } from "common/components/ui/Button";

import { favoriteFilterOptions } from "../../../../../../constants";

interface FavoriteFilterProps {
  isFavorite: boolean;
  setFilter: (filter: boolean) => void;
}

export default function FavoriteFilter({
  isFavorite,
  setFilter,
}: FavoriteFilterProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant={isFavorite ? "primary-black" : "tertiary-black"}
          leftIcon={<Star weight="fill" />}
          rightIcon={
            <CaretDown className="transition-transform group-data-[state=open]:rotate-180" />
          }
          className={clsx(
            "group",
            isFavorite
              ? "data-[state=open]:bg-black-700"
              : "data-[state=open]:bg-black-50",
          )}
        >
          Favorite
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {favoriteFilterOptions.map(({ text, value }) => (
          <DropdownMenuItem key={text} onClick={() => setFilter(value)}>
            {text}

            {isFavorite === value && <Check className="ml-auto" size={20} />}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
