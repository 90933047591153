import React from "react";
import { CaretDown, DotOutline } from "@phosphor-icons/react";
import clsx from "clsx";

import { typedObjectEntries } from "common/helpers/utils";
import { Campaign, CampaignState } from "common/types";
import { campaignStates } from "common/constants";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import Checkbox from "common/components/ui/Checkbox";
import { Button } from "common/components/ui/Button";

interface StateFilterProps {
  selectedFilters: CampaignState[];
  setFilters: (filter: CampaignState[]) => void;
  campaigns: Campaign[];
}

export default function StateFilter({
  campaigns,
  setFilters,
  selectedFilters,
}: StateFilterProps) {
  const filtersCount = selectedFilters.length;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="tertiary-black"
          className="group w-full lg:max-w-40"
          rightIcon={
            <CaretDown
              size={20}
              className="ml-auto transition-transform group-data-[state=open]:rotate-180"
            />
          }
        >
          {filtersCount
            ? `${filtersCount} ${filtersCount === 1 ? "status" : "statuses"}`
            : "All statuses"}
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        className="z-aboveDialog w-[--radix-dropdown-menu-trigger-width]"
        align="end"
      >
        <DropdownMenuItem onClick={() => setFilters([])}>
          All statuses
          <span className="text-black-500">
            ({campaigns ? campaigns.length : 0})
          </span>
        </DropdownMenuItem>

        {typedObjectEntries(campaignStates).map(
          ([key, { label, iconColor }]) => {
            const isSelected = selectedFilters.includes(key);

            return (
              <DropdownMenuItem
                key={key}
                onSelect={() => {
                  setFilters([key]);
                }}
              >
                <Checkbox
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={() => {
                    if (isSelected) {
                      setFilters(
                        selectedFilters.filter((filter) => filter !== key),
                      );
                    } else {
                      setFilters([...selectedFilters, key]);
                    }
                  }}
                />
                <DotOutline
                  size={20}
                  weight="fill"
                  className={clsx("ml-1.5", iconColor)}
                />
                {label}
                <span className="text-black-500">
                  {campaigns
                    ? campaigns.filter(({ state }) => state === key).length
                    : 0}
                </span>
              </DropdownMenuItem>
            );
          },
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
