import { X } from "@phosphor-icons/react";
import React, { useEffect, useRef } from "react";

import SearchInput from "common/components/SearchInput";
import LinkedInAccountsTag from "common/components/LinkedInAccountsTag";
import RenderIf from "common/components/RenderIf";
import useWorkspaceLabels from "common/datahooks/useWorkspaceLabels";
import LabelTag from "common/components/LabelTag";
import { LeadsSearchParams, SelectedFacetParam } from "common/types";

import ExportLeads from "./ExportLeads";
import useLeadsSearchParams from "../../hooks/useLeadsSearchParams";

export default function Heading({
  setSearchParams,
  searchParams,
  totalLeads,
  isLoading,
  keywordInputValue,
  setKeywordInputValue,
}: {
  searchParams: LeadsSearchParams<SelectedFacetParam>;
  setSearchParams: (
    searchParams: Partial<LeadsSearchParams<SelectedFacetParam>>,
  ) => void;
  totalLeads: number;
  isLoading: boolean;
  keywordInputValue: string;
  setKeywordInputValue: (keywordInputValue: string) => void;
}) {
  const { workspaceLabels } = useWorkspaceLabels();
  const timeoutRef = useRef(null);

  const { toggleSearchParam, setSearchParam } = useLeadsSearchParams(
    searchParams,
    setSearchParams,
  );

  useEffect(() => {
    if (keywordInputValue) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(
        () => setSearchParam("keywords", keywordInputValue),
        800,
      );
      return () => clearTimeout(timeoutRef.current);
    }
    setSearchParam("keywords", keywordInputValue);
  }, [keywordInputValue]);

  return (
    <>
      <div className="flex w-full flex-col-reverse gap-y-2 lg:flex-row lg:items-center lg:justify-between lg:gap-x-4">
        <SearchInput
          placeholder="Search Leads"
          onChange={(e) => setKeywordInputValue(e.target.value)}
          value={keywordInputValue}
          onClear={() => setKeywordInputValue("")}
          className="md:min-w-80"
        />
        <RenderIf condition={!!totalLeads}>
          <ExportLeads
            searchParams={searchParams}
            totalLeads={totalLeads}
            isDisabled={isLoading}
          />
        </RenderIf>
      </div>
      {!!searchParams.labels.length || !!searchParams.lead_of.length ? (
        <div className="mt-2 flex gap-2.5">
          {searchParams.lead_of.map((param) => (
            <LinkedInAccountsTag
              key={param.name}
              accountIds={[param.name]}
              size="lg"
              rightComponent={
                <button
                  type="button"
                  aria-label={`remove account filter ${param.name}`}
                  onClick={() => toggleSearchParam("lead_of", param)}
                >
                  <X size={16} className="fill-black-950" />
                </button>
              }
            />
          ))}
          {workspaceLabels &&
            searchParams.labels.map((param) => {
              const label = workspaceLabels.find(({ id }) => id === param.id);
              return (
                <LabelTag
                  key={label.id}
                  size="lg"
                  label={label}
                  onRemove={() => toggleSearchParam("labels", param)}
                />
              );
            })}
        </div>
      ) : null}
    </>
  );
}
