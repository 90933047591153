import React from "react";

import SearchInput from "common/components/SearchInput";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import { useDripFiltersContext } from "./DripFiltersContext";

export default function KeywordsSearch() {
  const isDesktop = useTwBreakpoint("lg");

  const {
    filters: { keywords },
    setFilters,
  } = useDripFiltersContext();

  return (
    <SearchInput
      value={keywords}
      onClear={() => setFilters({ keywords: "" })}
      variant={isDesktop ? "md" : "lg"}
      placeholder="Enter keyword"
      className="max-lg:mb-4 lg:w-96"
      onChange={(e) => setFilters({ keywords: e.currentTarget.value })}
    />
  );
}
