import React from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import { useDripFiltersContext } from "../../DripFiltersContext";
import DesktopLayout from "./DesktopLayout";
import MobileLayout from "./MobileLayout";

export default function FavoriteFilter() {
  const isDesktop = useTwBreakpoint("lg");
  const { filters, setFilters } = useDripFiltersContext();

  function setFilter(newValue: boolean) {
    setFilters({ favorite: newValue });
  }

  const Component = isDesktop ? DesktopLayout : MobileLayout;
  return <Component isFavorite={filters.favorite} setFilter={setFilter} />;
}
