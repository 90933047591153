import clsx from "clsx";
import React from "react";

import { PaymentSource } from "common/types";
import { cardIcons, paymentMethods } from "common/constants";

interface PaymentSourceProps {
  paymentSource: PaymentSource;
  selectedMethod: PaymentSource;
  setSelectedMethod: (paymentSource: PaymentSource) => void;
}

export default function PaymentSourceOption({
  paymentSource,
  selectedMethod,
  setSelectedMethod,
}: PaymentSourceProps) {
  // Destructure the payment source
  const { type, id } = paymentSource;

  // Get the icon for the payment method
  const Icon =
    type === "card"
      ? cardIcons[paymentSource.card.brand]
      : paymentMethods[type].icon;

  // Check if the payment source is selected
  const isSelected = selectedMethod?.id === id;

  return (
    <button
      className={clsx(
        "flex items-center gap-3 rounded-2xl p-3 transition-colors",
        isSelected
          ? "border-[3px] border-purple-200 bg-purple-50"
          : "border-transparent bg-black-100",
      )}
      onClick={() => setSelectedMethod(paymentSource)}
      type="button"
    >
      <Icon />

      <span className="text-body-14-bold">
        {type === "card"
          ? `Card ending with ${paymentSource.card.last4}`
          : paypal.email}
      </span>
    </button>
  );
}
