import React, { useCallback, useMemo, useState } from "react";
import { Star, TextAlignLeft } from "@phosphor-icons/react";

import { Tag } from "common/components/ui/Tag";
import { typedObjectEntries } from "common/helpers/utils";
import { DripFilter, Label } from "common/types";
import RenderIf from "common/components/RenderIf";
import LabelTag from "common/components/LabelTag";

import { DripTargeting } from "../../../types";
import { dripFacets } from "../../../constants";

function getTargetingLabels(targeting: DripTargeting, labels: Label[]) {
  return typedObjectEntries(targeting).map(([key, filters]) => {
    const items = Array.isArray(filters) ? filters : [filters];

    return items.map((item) => {
      const isValidValue = Array.isArray(item) ? !!item.length : !!item;

      if (!isValidValue) return null;

      switch (key) {
        case "keywords":
          return (
            <Tag
              leftIcon={<TextAlignLeft />}
              variant="secondary"
              key={item as string}
              size="lg"
            >
              {item as string}
            </Tag>
          );
        case "favorite":
          return (
            <Tag
              key="favorite"
              leftIcon={<Star weight="fill" />}
              size="lg"
              variant="primary-black"
            >
              Favorite
            </Tag>
          );
        case "labels": {
          const label = labels.find(({ id }) => id === (item as DripFilter).id);

          if (label) {
            return <LabelTag key={label.id} label={label} size="lg" />;
          }

          return null;
        }

        default: {
          const { icon: Icon } = dripFacets[key];
          const { id, name } = item as DripFilter;

          return (
            <Tag
              key={id}
              leftIcon={<Icon weight="fill" />}
              size="lg"
              variant="primary-black"
            >
              {name}
            </Tag>
          );
        }
      }
    });
  });
}

interface DripFiltersProps {
  targeting: DripTargeting;
  labels: Label[];
}

export default function DripFilters({ targeting, labels }: DripFiltersProps) {
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [showMoreIndex, setShowMoreIndex] = useState(0);

  // Merge locations with the same name
  const mergedTargeting = useMemo(() => {
    if (targeting) {
      const locationMap = new Map<string, DripFilter>();

      targeting.locations.forEach((location) => {
        if (!locationMap.has(location.name)) {
          locationMap.set(location.name, location);
        }
      });

      return {
        ...targeting,
        locations: Array.from(locationMap.values()),
      };
    }
  }, [targeting]);

  const measuredRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      const { children, clientWidth } = node;

      let tagsRowWidth = 0;

      for (let i = 0; i < children.length; i += 1) {
        const childWidth = children[i].clientWidth + 6; // +6 for gap

        if (tagsRowWidth + childWidth > clientWidth) {
          setShowMoreIndex(i);
          break;
        }

        tagsRowWidth += childWidth;
      }
    }
  }, []);

  const targetingLabels = getTargetingLabels(mergedTargeting, labels);

  const visibleLabels =
    isShowingMore || showMoreIndex === 0
      ? targetingLabels
      : targetingLabels.slice(0, showMoreIndex);

  return (
    <div ref={measuredRef} className="flex w-full flex-wrap gap-1.5">
      {visibleLabels}
      <RenderIf condition={showMoreIndex > 0}>
        <Tag
          size="lg"
          variant="quaternary"
          className="transition-colors hover:bg-black-200"
          asChild
        >
          <button
            type="button"
            onClick={() => setIsShowingMore((prev) => !prev)}
          >
            Show {isShowingMore ? "less" : "more"}
          </button>
        </Tag>
      </RenderIf>
    </div>
  );
}
