import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { HTTPError, post } from "common/helpers/HTTP";
import { Workspace, LinkedInProfile } from "common/types";

import useWorkspaceCacheUpdate from "./useWorkspaceCacheUpdate";

async function connectLinkedInAccountRequest(
  workspaceId: string,
  accountId: string,
) {
  await post(`workspaces/${workspaceId}/accounts`, {
    account_id: String(accountId),
  });
}

async function getAccountFromCookiesRequest(
  workspaceId: string,
  cookies: Cookie[],
  countryCode: string,
) {
  const formattedCookiesPayload = {
    cookies,
    proxy_config: {
      "location.countryISO": {
        $eq: countryCode,
      },
      "provider.name": {
        $eq: "nord",
      },
    },
  };

  return (
    await post(
      `workspaces/${workspaceId}/accounts-login-with-cookies`,
      formattedCookiesPayload,
    )
  ).profile;
}

interface Cookie {
  domain: string;
  expirationDate?: number;
  hostOnly: boolean;
  httpOnly: boolean;
  name: string;
  path: string;
  sameSite: string;
  secure: boolean;
  session: boolean;
  storeId: string;
  value: string;
}

export default function useAimfoxExtension(
  workspaceId: string,
  workspaceAccountId: string,
  countryCode: string,
) {
  const { updateCachedWorkspace } = useWorkspaceCacheUpdate();

  const {
    mutateAsync: connectLinkedInAccount,
    isPending: isConnectingAccount,
  } = useMutation({
    mutationFn: ({
      accountId,
    }: {
      accountId: string;
      account: LinkedInProfile;
    }) => connectLinkedInAccountRequest(workspaceId, accountId),
    // For optimistic update of the accounts list we're using the accountId and account data from the extension
    onSuccess: (_, { accountId, account }) => {
      updateCachedWorkspace(workspaceId, (draftWorkspace: Workspace) => {
        draftWorkspace.accounts.push({
          ...account,
          id: accountId.toString(),
          full_name: `${account.first_name} ${account.last_name}`,
          state: "LoggedIn",
          license: null,
        });
      });
      toast.success("Account connected");
    },
    onError: (error) => {
      if (error instanceof HTTPError && error.code === 409) {
        toast.error(
          "This account is already in use and cannot be connected again",
        );
      } else {
        toast.error(
          "Failed to connect. Please make sure you are logged into your LinkedIn account",
        );
      }
    },
  });

  const { mutateAsync: getAccountFromCookies, isPending: isGettingAccount } =
    useMutation({
      mutationFn: (cookies: Cookie[]) =>
        getAccountFromCookiesRequest(workspaceId, cookies, countryCode),
      onSuccess: (profile: LinkedInProfile) => {
        const profileId = profile.id.toString();

        if (workspaceAccountId) {
          if (workspaceAccountId === profileId) {
            updateCachedWorkspace(workspaceId, (draftWorkspace: Workspace) => {
              const updatedAccount = draftWorkspace.accounts.find(
                ({ id }) => id === profileId,
              );

              if (!updatedAccount) {
                toast.error(
                  "Account not found. Please verify your logged-in LinkedIn account.",
                );
                return;
              }

              updatedAccount.state = "LoggedIn";
            });
          } else {
            toast.error(
              "The logged-in LinkedIn account doesn't match the account you're trying to reconnect. Please log in with the correct account",
            );
          }
        }
      },
      onError: (error) => {
        if (error instanceof HTTPError && error.code === 401) {
          toast.error(
            "Please make sure you are logged into your LinkedIn account",
          );
        } else {
          toast.error("Failed to connect with Aimfox Chrome Extension");
        }
      },
    });

  return {
    connectLinkedInAccount,
    isConnectingAccount,
    getAccountFromCookies,
    isGettingAccount,
  };
}
