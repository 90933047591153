import React from "react";
import { Chat, Envelope, NoteBlank, type Icon } from "@phosphor-icons/react";

import { TemplateType } from "common/types";
import { Button } from "common/components/ui/Button";
import { useCampaignContext } from "common/helpers/CampaignContext";

function getSectionValues(templateType: TemplateType, isDrip: boolean) {
  const flowSectionOptions: Record<
    TemplateType,
    {
      title: string;
      description: string;
      icon: Icon;
    }
  > = {
    NOTE_TEMPLATE: {
      title: "Connection Request",
      description:
        "Send a connection request to your targets. This step is always active",
      icon: NoteBlank,
    },
    INMAIL_TEMPLATE: {
      title: "Free InMails",
      description:
        "Send a free InMail to Premium LinkedIn users. This will never use your paid InMails",
      icon: Envelope,
    },
    MESSAGE_TEMPLATE: {
      title: `${isDrip ? "Drip " : ""}Message Sequence`,
      description: isDrip
        ? "Send a Direct Message to your campaign targets"
        : "Automatically send Follow-Up messages to your leads after they accept your Connection request",
      icon: Chat,
    },
  };

  return flowSectionOptions[templateType];
}

interface FlowSectionHeadingProps {
  templateType: TemplateType;
}

export default function FlowSectionHeading({
  templateType,
}: FlowSectionHeadingProps) {
  const { type: campaignType } = useCampaignContext();
  const isDrip = campaignType === "drip";

  const {
    title,
    description,
    icon: Icon,
  } = getSectionValues(templateType, isDrip);

  return (
    <div className="flex items-center gap-3">
      <Button intent="labelIcon" variant="secondary-black">
        <Icon />
      </Button>

      <div>
        <h3 className="mb-0.5 text-button-16">{title}</h3>
        <p className="text-caption-12-regular text-black-500">{description}</p>
      </div>
    </div>
  );
}
