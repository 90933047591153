import { CaretRight, Minus, Plus } from "@phosphor-icons/react";
import React, { useState } from "react";
import clsx from "clsx";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { DialogFooter } from "common/components/ui/Dialog";
import { DrawerFooter } from "common/components/ui/Drawer";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "common/components/ui/Tooltip";
import { isLifetimeDeal } from "common/helpers/utils";
import { PricingPeriodUnit } from "common/types";

import PricingInfo from "./PricingInfo";

const planOptions: {
  value: PricingPeriodUnit;
  label: string;
}[] = [
  {
    value: "month",
    label: "Monthly",
  },
  {
    value: "year",
    label: "Yearly",
  },
];

interface ManageSeatsProps {
  licenseCount: number;
  setLicenseCount: (newCount: number) => void;
  selectedPeriodUnit: PricingPeriodUnit;
  setSelectedPeriodUnit: (newPeriodUnit: PricingPeriodUnit) => void;
  goToNextStep: () => void;
}

const MAX_SEATS = 9999;

export default function ManageSeats({
  licenseCount,
  setLicenseCount,
  selectedPeriodUnit,
  setSelectedPeriodUnit,
  goToNextStep,
}: ManageSeatsProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const Footer = isTabletOrDesktop ? DialogFooter : DrawerFooter;

  const {
    subscription,
    license_count: workspaceLicenseCount,
    licenses,
  } = useSelectedWorkspaceContext();

  const lifetimeLicenseCount = licenses.filter(
    ({ lifetime }) => lifetime,
  ).length;

  const isYearlySubscription =
    subscription && subscription.item_price.period_unit === "year";

  const isMinusDisabled = isLifetimeDeal(licenses)
    ? licenseCount === lifetimeLicenseCount
    : licenseCount === 0;

  let isNextButtonDisabled = licenseCount === 0;

  if (!subscription && isLifetimeDeal(licenses)) {
    isNextButtonDisabled = licenseCount === workspaceLicenseCount;
  } else if (subscription) {
    isNextButtonDisabled =
      licenseCount === workspaceLicenseCount &&
      selectedPeriodUnit === subscription.item_price.period_unit;
  }

  const [inputValue, setInputValue] = useState(licenseCount.toString());

  return (
    <>
      <h3 className="mb-8 text-center text-headline-2xl-bold">Manage seats</h3>

      <div
        className={clsx(
          "mx-auto flex w-[224px] items-center gap-x-1 rounded-full p-1",
          isYearlySubscription ? "bg-black-200" : "bg-black-100",
        )}
      >
        {planOptions.map(({ value, label }) => {
          const isSelected = value === selectedPeriodUnit;

          if (isYearlySubscription && value === "month") {
            return (
              <Tooltip key={value}>
                <TooltipTrigger asChild>
                  <Button
                    variant="quaternary-black"
                    size="sm"
                    className="flex-1"
                    disabled
                  >
                    {label}
                  </Button>
                </TooltipTrigger>

                <TooltipContent side="bottom">
                  If you want to switch from a yearly to monthly subscription,
                  contact our support team through the live chat
                </TooltipContent>
              </Tooltip>
            );
          }

          return (
            <Button
              onClick={() => setSelectedPeriodUnit(value)}
              key={value}
              variant={isSelected ? "primary-black" : "quaternary-black"}
              size="sm"
              className="flex-1"
            >
              {label}
            </Button>
          );
        })}
      </div>

      <div className="my-4 flex items-center justify-between gap-x-6 rounded-2xl bg-purple-100 px-6 py-2 ring ring-purple-200">
        <Button
          intent="iconOnly"
          variant="secondary-purple"
          onClick={() => {
            setInputValue((Number(inputValue) - 1).toString());
            setLicenseCount(Number(inputValue) - 1);
          }}
          disabled={isMinusDisabled}
        >
          <Minus />
        </Button>

        <input
          className="h-10 w-full rounded-xl bg-white text-center text-body-16-bold text-black-700 [appearance:textfield] focus:outline-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={inputValue}
          maxLength={4}
          onChange={(e) => {
            const { value } = e.currentTarget;
            setInputValue(value);

            const numericValue = Number(value);
            // Only update if it's a valid number and above minimum
            if (
              !Number.isNaN(numericValue) &&
              numericValue >= lifetimeLicenseCount
            ) {
              // Clamp the value between min and max
              const clampedValue = Math.min(numericValue, MAX_SEATS);
              setLicenseCount(clampedValue);
            }
          }}
          onBlur={() => {
            const numericValue = Number(inputValue);

            // On blur, ensure the value respects the minimum limit
            if (
              Number.isNaN(numericValue) ||
              numericValue < lifetimeLicenseCount
            ) {
              setLicenseCount(lifetimeLicenseCount);
              setInputValue(lifetimeLicenseCount.toString());
            } else if (numericValue > MAX_SEATS) {
              setLicenseCount(MAX_SEATS);
              setInputValue(MAX_SEATS.toString());
            } else {
              // Ensure the input shows the actual value
              setInputValue(numericValue.toString());
            }
          }}
        />

        <Button
          intent="iconOnly"
          variant="secondary-purple"
          onClick={() => {
            setInputValue((Number(inputValue) + 1).toString());
            setLicenseCount(Number(inputValue) + 1);
          }}
          disabled={licenseCount >= MAX_SEATS}
        >
          <Plus />
        </Button>
      </div>

      <PricingInfo
        licenseCount={licenseCount}
        selectedPeriodUnit={selectedPeriodUnit}
      />

      <Footer className="mt-6 md:-mx-16">
        <Button
          rightIcon={<CaretRight />}
          size="lg"
          className="ml-auto"
          onClick={goToNextStep}
          disabled={isNextButtonDisabled}
        >
          Next
        </Button>
      </Footer>
    </>
  );
}
