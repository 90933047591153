import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";

async function getCelloToken() {
  const { token } = await get("customers/cello-token");
  return token;
}
export default function useCelloToken() {
  const { data: celloToken, isLoading: isLoadingCelloToken } = useQuery({
    queryKey: ["celloToken"],
    queryFn: getCelloToken,
  });
  
  return { celloToken, isLoadingCelloToken };
}
