import React, { Fragment } from "react";

import RenderIf from "common/components/RenderIf";

import useMessageTemplateActions from "../../../hooks/useMessageTemplateActions";
import Placeholder from "../Placeholder";
import MessageDelay from "./MessageDelay";
import FlowSectionHeading from "../FlowSectionHeading";
import FlowTemplate from "../FlowTemplate";
import { FlowMessageTemplate } from "../../../types";
import { useCampaignFlowContext } from "../../../context/CampaignFlowContext";

interface MessageTemplatesProps {
  messageTemplates: FlowMessageTemplate[];
}

export default function MessageTemplates({
  messageTemplates,
}: MessageTemplatesProps) {
  const { flow, isFlowError } = useCampaignFlowContext();
  const isPlaceholderDisabled = messageTemplates.some(
    (template) => !template.original_id,
  );

  const errorTextComponent = (
    <p className="-mt-2 text-caption-12-regular text-red-500 lg:-mt-4">
      You must add at least one message template
    </p>
  );

  const {
    addMessageTemplate,
    setMessageTemplate,
    setMessageDelay,
    removeLastMessage,
    toggleMessageTemplateAi,
    addPlaceholderMessage,
    removePlaceholderMessage,
  } = useMessageTemplateActions(messageTemplates);

  return (
    <>
      <FlowSectionHeading templateType="MESSAGE_TEMPLATE" />

      <div className="flex flex-col gap-4 lg:gap-6">
        {messageTemplates.map((messageTemplate, index, { length }) => {
          const isLastItem = index + 1 === length;
          const isValidTemplate = messageTemplate.original_id;
          const shouldHideDelay =
            index === 0 && flow.type === "PRIMARY_MESSAGE";

          const messageDelayEditComponent = (
            <MessageDelay
              delay={messageTemplate.delay}
              onDelayChange={(delay) => {
                setMessageDelay(index, delay);
              }}
              messageIndex={index}
              isDisabled={!isValidTemplate}
            />
          );

          const messageDelayComponent = (
            <MessageDelay
              delay={messageTemplate.delay}
              messageIndex={index}
              isDisabled={!isValidTemplate}
            />
          );

          return (
            <Fragment key={messageTemplate.original_id || index}>
              <FlowTemplate
                flowTemplate={messageTemplate}
                setFlowTemplate={(newTemplate) => {
                  if (isValidTemplate) {
                    setMessageTemplate(
                      index,
                      newTemplate as FlowMessageTemplate,
                    );
                  } else {
                    addMessageTemplate(newTemplate as FlowMessageTemplate);
                  }
                }}
                onRemove={
                  isValidTemplate ? removeLastMessage : removePlaceholderMessage
                }
                toggleAi={() => toggleMessageTemplateAi(index)}
                messageDelayComponent={
                  !shouldHideDelay && messageDelayComponent
                }
                messageDelayEditComponent={
                  !shouldHideDelay && messageDelayEditComponent
                }
                hideRemoveButton={!isLastItem || shouldHideDelay}
              />
              <RenderIf condition={isFlowError}>{errorTextComponent}</RenderIf>
            </Fragment>
          );
        })}

        <RenderIf condition={messageTemplates.length < 10}>
          <Placeholder
            onAdd={addPlaceholderMessage}
            templateType="MESSAGE_TEMPLATE"
            isDisabled={isPlaceholderDisabled}
          />
        </RenderIf>

        <RenderIf condition={isFlowError && !messageTemplates.length}>
          {errorTextComponent}
        </RenderIf>
      </div>
    </>
  );
}
