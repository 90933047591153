import React from "react";

import Skeleton from "common/components/ui/Skeleton";

export default function RecentLeadsItemLoader() {
  return (
    <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3">
      {new Array(18).fill({}).map((_, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className="pointer-events-none flex items-center gap-x-3 rounded-2xl border border-black-200 px-4 py-3"
        >
          <Skeleton className="size-10" />

          <div className="flex flex-1 flex-col gap-y-0.5">
            <Skeleton className="inline-flex h-[19px] w-6/12" />
            <Skeleton className="inline-flex h-[17px] w-8/12" />
          </div>
        </div>
      ))}
    </div>
  );
}
