import React from "react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "common/components/ui/Accordion";
import { Tag } from "common/components/ui/Tag";
import RenderIf from "common/components/RenderIf";
import { Facets, Label } from "common/types";
import { typedObjectEntries } from "common/helpers/utils";

import { dripFacets } from "../../../../../constants";
import DripFacet from "./DripFacet";
import { useDripFiltersContext } from "../DripFiltersContext";
import FavoriteFilter from "./FavoriteFilter";

interface FiltersProps {
  facets: Facets;
  isLoading: boolean;
  labels: Label[];
}

export default function MobileFilters({
  facets,
  isLoading,
  labels,
}: FiltersProps) {
  const {
    filters: { keywords, ...dripFilters },
  } = useDripFiltersContext();

  return (
    <Accordion type="multiple" className="mb-4">
      {typedObjectEntries(dripFacets).map(([key, { icon: Icon, label }]) => {
        const facetKey = key === "education" ? "schools" : key;
        const allFacets = facets ? facets[facetKey] : [];

        const selectedFiltersCount = dripFilters[key].length;

        return (
          <AccordionItem value={key} key={key}>
            <AccordionTrigger className="h-14">
              <Icon weight={key === "origins" ? "regular" : "fill"} size={20} />
              <span className="line-clamp-1 break-all">{label}</span>

              <RenderIf condition={!!selectedFiltersCount}>
                <Tag variant="primary-black">{selectedFiltersCount}</Tag>
              </RenderIf>
            </AccordionTrigger>
            <AccordionContent>
              <DripFacet
                allFacets={allFacets}
                label={label}
                searchKey={key}
                isLoading={isLoading}
                labels={labels}
              />
            </AccordionContent>
          </AccordionItem>
        );
      })}
      <FavoriteFilter />
    </Accordion>
  );
}
