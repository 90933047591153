import { useMemo } from "react";

import { FacetParam, Facets } from "common/types";

// We merge location facets that have the same name but different IDs
export default function useMergedFacets(facets: Facets): Facets {
  return useMemo(() => {
    if (facets) {
      const locationMap = new Map<string, FacetParam>();

      facets.locations.forEach((location) => {
        const normalizedLocation: FacetParam = {
          ...location,
          id: typeof location.id === "string" ? [location.id] : location.id,
        };

        if (locationMap.has(location.name)) {
          const existing = locationMap.get(location.name);
          locationMap.set(location.name, {
            ...existing,
            target_count: existing.target_count + location.target_count,
            id: [...existing.id, ...normalizedLocation.id],
          });
        } else {
          locationMap.set(location.name, normalizedLocation);
        }
      });

      return {
        ...facets,
        locations: Array.from(locationMap.values()),
      };
    }
  }, [facets]);
}
