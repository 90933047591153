import React from "react";
import { MinusSquare, Trash } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import SearchInput from "common/components/SearchInput";
import Loader from "common/components/Loader";

import useAudienceProgress from "./useAudienceProgress";
import useAllResults from "./useAllResults";
import ExportAudience from "./ExportAudience";
import useAudience from "../../../datahooks/useAudience";
import { AudienceStats } from "../../../types";
import AudienceDetails from "./AudienceDetails";
import AudienceProgress from "./AudienceProgress";
import AudienceHeader from "./AudienceHeader";
import Stepper from "../Stepper";
import useTargetingMutations from "../../../datahooks/useTargetingMutations";
import AudiencePreview from "./AudiencePreview";

import NoTargetsFoundImage from "assets/images/empty-placeholders/no-profiles.png";
import ProgressImage from "assets/images/empty-placeholders/progress.png";

interface AudienceProps {
  audienceStats: AudienceStats;
  isLoadingAudienceStats: boolean;
}

export default function Audience({
  audienceStats,
  isLoadingAudienceStats,
}: AudienceProps) {
  useAudienceProgress();
  const navigate = useNavigate();
  const { t } = useTranslation("campaigns", { keyPrefix: "audience" });
  const isTabletOrDesktop = useTwBreakpoint("md");

  const { removeTargeting, isRemovingTargeting } = useTargetingMutations();
  const { audience, isLoadingAudience, removeProfiles, isRemovingProfiles } =
    useAudience();

  const {
    toggleSelection,
    selectedProfiles,
    searchValue,
    setSearchValue,
    filteredResults,
    resetSelectedProfiles,
  } = useAllResults(isLoadingAudience ? [] : audience);

  const targetUrns = selectedProfiles.map((profile) => profile.urn);
  const isMiningTargets = audienceStats && audienceStats.progress !== 100;

  if (isLoadingAudienceStats || isLoadingAudience) {
    return <Loader />;
  }

  const handleRemoveTargets = () => {
    removeProfiles({ targetUrns }).then(() => resetSelectedProfiles());
  };

  return (
    <>
      <AudienceHeader
        isMiningTargets={isMiningTargets}
        audienceCount={audience ? audience.length : 0}
      />
      <AudienceProgress
        progress={audienceStats.progress}
        audienceCount={audience.length}
      />
      {audience && !!audience.length ? (
        <>
          <div className="mb-4 flex flex-col items-center justify-between gap-2 md:flex-row">
            <SearchInput
              variant={isTabletOrDesktop ? "md" : "lg"}
              className="w-full md:max-w-[350px] xl:max-w-[450px]"
              placeholder="Search audience"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onClear={() => setSearchValue("")}
            />
            <div className="flex gap-3 max-md:w-full">
              {selectedProfiles.length > 0 ? (
                <>
                  <Button
                    intent="iconOnly"
                    variant="tertiary-purple"
                    size={isTabletOrDesktop ? "lg" : "md"}
                    onClick={resetSelectedProfiles}
                  >
                    <MinusSquare />
                  </Button>
                  <Button
                    className="max-md:flex-1"
                    variant="tertiary-danger"
                    size={isTabletOrDesktop ? "lg" : "md"}
                    leftIcon={<Trash />}
                    disabled={isRemovingProfiles}
                    onClick={handleRemoveTargets}
                  >
                    Remove {selectedProfiles.length}
                    {isTabletOrDesktop
                      ? ` Target${selectedProfiles.length > 1 ? "s" : ""}`
                      : ""}
                  </Button>
                </>
              ) : (
                <AudienceDetails audienceStats={audienceStats} />
              )}

              <ExportAudience
                audience={audience}
                selectedProfiles={selectedProfiles}
              />
            </div>
          </div>
          <AudiencePreview
            profiles={filteredResults}
            toggleSelection={toggleSelection}
            selectedProfiles={selectedProfiles}
            searchValue={searchValue}
          />
        </>
      ) : (
        <EmptyPlaceholder
          title={t(
            isMiningTargets ? "loadingTargetsTitle" : "noTargetsFoundTitle",
          )}
          subtitle={t(
            isMiningTargets
              ? "loadingTargetsSubtitle"
              : "noTargetsFoundSubtitle",
          )}
          imageSrc={isMiningTargets ? ProgressImage : NoTargetsFoundImage}
          actionComponent={
            !isMiningTargets && (
              <div className="flex flex-col gap-2 md:flex-row">
                <Button
                  size={isTabletOrDesktop ? "lg" : "md"}
                  onClick={() => removeTargeting()}
                  isLoading={isRemovingTargeting}
                >
                  Edit targeting
                </Button>
                <AudienceDetails audienceStats={audienceStats} />
              </div>
            )
          }
        />
      )}
      <Stepper
        selectedIndex={1}
        isNextStepDisabled={!audience.length}
        goToNextStep={() => navigate("../flows")}
        isMiningTargets={isMiningTargets}
      />
    </>
  );
}
