import React from "react";
import { useTranslation } from "react-i18next";

import useSelectedMetric from "common/hooks/useSelectedMetric";
import useMetrics from "common/datahooks/useMetrics";
import { Metrics } from "common/types";
import RenderIf from "common/components/RenderIf";
import RestrictedComponent from "common/components/RestrictedComponent";
import MetricSelector from "common/components/MetricSelector";
import StatisticsGraph from "common/components/StatisticsGraph";
import TimeframeSelector from "common/components/TimeframeSelector";
import RecentLeads from "common/components/RecentLeads";
import { useCampaignContext } from "common/helpers/CampaignContext";

import CampaignFlow from "../../CampaignFlow";
import { CampaignFlow as FlowType } from "../../../types";
import { campaignOutreachOptions } from "../../../constants";
import { getFlowTitle } from "../../../utils";
import ChangeFlowName from "./ChangeFlowName";
import PauseCampaign from "./PauseCampaign";
import FlowTypeLabel from "../../FlowTypeLabel";

export default function FlowDetails({ flow }: { flow: FlowType }) {
  const {
    type,
    outreach_type: outreachType,
    state,
    owner,
    id: campaignId,
  } = useCampaignContext();
  const isDrip = type === "drip";

  const { t } = useTranslation("campaigns", { keyPrefix: "flowTypes" });
  const isPrimaryFlow =
    flow.type === "PRIMARY_CONNECT" || flow.type === "PRIMARY_MESSAGE";

  const {
    setSelectedMetric,
    selectedMetric,
    selectedMetricParams,
    setSelectedMetricParams,
  } = useSelectedMetric();
  const {
    metrics,
    metricsSum,
    refetchMetrics,
    metricsError,
    isLoadingMetrics,
  } = useMetrics({
    flowId: flow.id,
    parameters: selectedMetricParams,
    accountIds: [],
  });

  const hiddenColumnsBase: (keyof Metrics)[] = [
    "views",
    ...campaignOutreachOptions[outreachType].hiddenMetricsColumns,
    ...(flow.type === "INMAIL_OPTIMIZATION"
      ? ([
          "sent_connections",
          "sent_messages",
          "accepted_connections",
          "message_requests",
        ] as (keyof Metrics)[])
      : (["sent_inmails"] as (keyof Metrics)[])),
    ...(isDrip ? (["message_requests"] as (keyof Metrics)[]) : []),
  ];

  const hiddenColumns = [...new Set(hiddenColumnsBase)];

  const canEditFlow = state === "CREATED" || state === "PAUSED";

  return (
    <>
      <section className="mb-4 flex flex-col justify-between gap-4 md:flex-row md:items-start">
        <div className="flex flex-col">
          <h2 className="text-headline-xl-bold">
            {getFlowTitle(flow)} overview
          </h2>
          <p className="text-black-500">
            A detailed overview of all the interactions in this flow
          </p>
        </div>

        <TimeframeSelector
          selectedParams={selectedMetricParams}
          setSelectedParams={setSelectedMetricParams}
        />
      </section>

      <MetricSelector
        metricsSum={metricsSum}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        totalMetrics={flow.metrics}
        isLoading={isLoadingMetrics}
        hiddenColumns={hiddenColumns}
      />

      <div className="relative my-6 flex h-[420px] md:h-[480px]">
        <StatisticsGraph
          selectedMetric={selectedMetric}
          metrics={metrics}
          metricsSum={metricsSum}
          requestError={metricsError}
          isLoading={isLoadingMetrics}
          isHourly={selectedMetricParams.bucketSize === "1 hour"}
          refetchMetrics={refetchMetrics}
        />
      </div>

      <RestrictedComponent disabledForRoles={[]} hiddenForRoles={["member"]}>
        <RenderIf condition={state === "ACTIVE"}>
          <PauseCampaign campaignId={campaignId} />
        </RenderIf>
      </RestrictedComponent>

      <RestrictedComponent disabledForRoles={["member"]} hiddenForRoles={[]}>
        {(isDisabled) => (
          <div className="mt-4 rounded-2xl border border-black-200 px-3 py-4 md:px-4">
            {/* Section heading */}
            <div className="-mx-3 mb-4 flex gap-3 border-b border-black-200 px-3 pb-4 md:-mx-4 md:px-4">
              <div className="flex gap-3">
                <FlowTypeLabel type={flow.type} />
                <div>
                  <h3 className="mb-1 text-headline-lg-bold">
                    {getFlowTitle(flow)}
                  </h3>
                  <p className="text-black-500">
                    {t(`${flow.type}_Description`)}
                  </p>
                </div>
              </div>
              <RenderIf condition={isPrimaryFlow}>
                <ChangeFlowName
                  isDisabled={isDisabled || !canEditFlow}
                  flow={flow}
                />
              </RenderIf>
            </div>

            <CampaignFlow
              flow={flow}
              canEditFlow={!isDisabled && canEditFlow}
            />
          </div>
        )}
      </RestrictedComponent>

      <RecentLeads
        campaignId={campaignId}
        flowId={flow.id}
        accountIds={[owner]}
        isDrip={isDrip}
      />
    </>
  );
}
