import React from "react";
import { CaretDown, LinkedinLogo } from "@phosphor-icons/react";
import clsx from "clsx";

import { WorkspaceAccount } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";

import ItemContainer from "./ItemContainer";

interface LinkedInAccountSelectProps {
  accounts: WorkspaceAccount[];
  selectedAccount: WorkspaceAccount;
  setSelectedAccount: (account: WorkspaceAccount) => void;
}

export default function LinkedInAccountSelect({
  accounts,
  selectedAccount,
  setSelectedAccount,
}: LinkedInAccountSelectProps) {
  return (
    <ItemContainer
      icon={LinkedinLogo}
      title="LinkedIn Account "
      description="What LinkedIn account will run this campaign"
    >
      <DropdownMenu>
        <DropdownMenuTrigger className="group flex w-48 items-center gap-x-2 rounded-full border border-black-200 px-3 py-2 max-md:ml-[60px]">
          <ProfileImage
            key={selectedAccount.id}
            size="xs"
            className="rounded-full ring-1 ring-purple-50"
            src={selectedAccount.picture_url}
          />
          <span className="truncate text-button-14">
            {selectedAccount.full_name}
          </span>
          <CaretDown
            size={20}
            className="ml-auto transition-transform group-data-[state=open]:rotate-180"
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          className="z-aboveDialog max-md:w-[--radix-popper-anchor-width]"
        >
          {accounts.map((account) => (
            <DropdownMenuItem
              onSelect={() => setSelectedAccount(account)}
              key={account.id}
              className="gap-x-1"
            >
              <ProfileImage
                size="2xs"
                className={clsx(
                  "rounded-full",
                  account.license &&
                    "border border-purple-500 ring-1 ring-purple-500",
                )}
                src={account.picture_url}
              />
              <span className="line-clamp-1 break-all">
                {account.full_name}
              </span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </ItemContainer>
  );
}
