import React from "react";
import { User } from "@phosphor-icons/react";

import { Lead } from "common/types";
import { Button } from "common/components/ui/Button";
import ProfileImage from "common/components/ui/ProfileImage";
import Skeleton from "common/components/ui/Skeleton";

interface ProfileCardProps {
  isLoading: boolean;
  lead?: Lead;
  openPreview?: () => void;
}

export default function ProfileCard({
  isLoading,
  lead = null,
  openPreview = undefined,
}: ProfileCardProps) {
  const {
    picture_url: pictureUrl,
    full_name: fullName,
    occupation,
  } = lead ?? {};

  return (
    <div className="flex h-16 items-center rounded-2xl border border-black-200 px-4">
      {isLoading ? (
        <Skeleton className="size-10" />
      ) : (
        <ProfileImage src={pictureUrl} alt={`${fullName} Profile Image`} />
      )}

      <div className="ml-2 mr-3 flex flex-1 flex-col gap-y-0.5">
        {isLoading ? (
          <>
            <Skeleton className="h-[19px] w-1/2" />
            <Skeleton className="h-[17px] w-10/12" />
          </>
        ) : (
          <>
            <h4 className="line-clamp-1 break-all text-button-14">
              {fullName}
            </h4>
            <p className="line-clamp-1 break-all text-caption-12-regular">
              {occupation}
            </p>
          </>
        )}
      </div>

      <Button
        intent="iconOnly"
        variant="secondary-purple"
        onClick={openPreview}
        disabled={isLoading}
      >
        <User />
      </Button>
    </div>
  );
}
