import React from "react";
import { useIntercom } from "react-use-intercom";
import { Gift, Lifebuoy } from "@phosphor-icons/react";
import { useSearchParams } from "react-router-dom";

import { Button } from "common/components/ui/Button";
import useAppStore from "common/hooks/useAppStore";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import TrialBanner from "common/layouts/WorkspaceLayout/TrialBanner";
import RenderIf from "common/components/RenderIf";

import DebugPopup from "./DebugPopup";
import NotificationsPopup from "./NotificationsPopup";
import WorkspacesPopup from "./WorkspacesPopup";

import RoadmapIcon from "assets/icons/roadmap.svg?react";

interface DesktopHeaderProps {
  headerTitle: string;
}

export default function DesktopHeader({ headerTitle }: DesktopHeaderProps) {
  const { subscription } = useSelectedWorkspaceContext();
  const { show } = useIntercom();
  const headerContent = useAppStore((state) => state.headerContent);
  const [searchParams] = useSearchParams();

  const isCelloEnabled = searchParams.get("showCello") === "true";

  return (
    <header className="flex h-20 w-full items-center justify-between gap-x-4 border-b border-b-black-200 px-4">
      <div className="flex items-center gap-x-1.5">
        <h1 className="text-headline-xl-bold">{headerTitle}</h1>
        {headerContent?.component}
        {subscription && subscription.status === "in_trial" && <TrialBanner />}
      </div>

      <section className="ml-auto flex items-center gap-x-2">
        <RenderIf condition={isCelloEnabled && !!window.Cello}>
          <div className="relative" id="cello-button">
            <Button
              id="cello-announcement"
              intent="iconOnly"
              variant="quaternary-black"
              aria-label="referral"
              data-intercom-target="cello-button"
              onClick={() => window.Cello("open")}
            >
              <Gift weight="fill" />
            </Button>
          </div>
        </RenderIf>

        <Button
          intent="iconOnly"
          variant="quaternary-black"
          aria-label="support"
          onClick={show}
        >
          <Lifebuoy weight="fill" />
        </Button>

        {import.meta.env.MODE !== "production" && <DebugPopup />}

        <NotificationsPopup />

        <Button leftIcon={<RoadmapIcon />} size="sm" asChild>
          <a
            href="https://aimfoxroadmap.ideas.userback.io/p/bAyZ6Pnxxx/roadmap?sort_by=trending"
            target="_blank"
            rel="noreferrer"
          >
            Roadmap
          </a>
        </Button>
      </section>

      <WorkspacesPopup />
    </header>
  );
}
