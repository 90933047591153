import * as React from "react";
import { useIntercom } from "react-use-intercom";
import { Lifebuoy, X, SignOut, UserGear, Gift } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Sheet, SheetContent, SheetTrigger } from "common/components/ui/Sheet";
import { Button } from "common/components/ui/Button";
import { SheetClose } from "common/components/ui/Sheet/Sheet";
import { NavItemType } from "common/types";
import useCustomer from "common/datahooks/useCustomer";
import Avatar from "common/components/ui/Avatar";

import NavItem from "../NavItem";
import LogOutModal from "../LogOutModal";

import AimfoxLogoOrange from "assets/aimfoxLogos/aimfox-logo-orange.svg?react";
import HamburgerIcon from "assets/icons/hamburger.svg?react";
import RoadmapIcon from "assets/icons/roadmap.svg?react";

export default function MobileNav({ navItems }: { navItems: NavItemType[] }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLogOutOpen, setIsLogOutOpen] = useState(false);
  const location = useLocation();
  const { show } = useIntercom();

  const { customerInfo } = useCustomer();
  const { first_name: firstName, last_name: lastName, email } = customerInfo;

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  function onLogOutItemClick() {
    setIsLogOutOpen(true);
    setIsOpen(false);
  }

  return (
    <>
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetTrigger asChild>
          <Button size="lg" variant="tertiary-black" intent="iconOnly">
            <HamburgerIcon />
          </Button>
        </SheetTrigger>
        <SheetContent
          side="right"
          className="flex w-10/12 flex-col rounded-tl-3xl bg-black-950 px-4 py-8"
        >
          <div className="flex justify-between">
            <div className="flex items-end">
              <AimfoxLogoOrange className="w-8" />
              <span className="mb-0.5 text-headline-lg-bold text-white">
                Aimfox
              </span>
            </div>
            <SheetClose>
              <X className="fill-white" size={24} />
            </SheetClose>
          </div>
          <div className="flex gap-3 py-10">
            <Avatar size="lg">
              {firstName[0]}
              {lastName[0]}
            </Avatar>
            <div className="flex flex-col">
              <span className="line-clamp-1 break-all text-headline-xl-bold text-white">
                {firstName} {lastName}
              </span>
              <span className="line-clamp-1 break-all text-black-400">
                {email}
              </span>
            </div>
          </div>
          <div className="overflow-y-auto scrollbar-thin">
            {navItems.map((navItem) => (
              <NavItem key={navItem.path} {...navItem} />
            ))}

            <div className="mb-4 mt-10 h-px w-full bg-black-600" />

            <NavItem
              label="Profile Settings"
              renderIcon={(props) => <UserGear {...props} />}
              path="/profile-settings"
              state={{ canGoBack: true }}
            />

            {/* Cello wrapper div */}
            {window.Cello && (
              // TODO: Remove hidden class when Cello is ready
              <button
                type="button"
                id="cello-button"
                data-intercom-target="cello-button"
                className="relative flex h-14 w-[calc(100%-5px)] items-center justify-start gap-x-2 text-nowrap rounded-lg px-2.5 text-button-16 text-black-600 hover:bg-black-800 hover:text-white"
                onClick={() => window.Cello("open")}
              >
                <Gift weight="fill" className="size-5" />
                <span id="cello-announcement">Referral</span>
              </button>
            )}

            <a
              href="https://aimfoxroadmap.ideas.userback.io/p/bAyZ6Pnxxx/roadmap?sort_by=trending"
              target="_blank"
              rel="noreferrer"
              className="flex h-14 w-full items-center justify-start gap-x-2 text-nowrap rounded-lg px-2.5 text-button-16 text-black-600 hover:bg-black-800 hover:text-white"
            >
              <RoadmapIcon className="size-5" />
              Roadmap
            </a>

            <NavItem
              label="Support"
              renderIcon={(props) => <Lifebuoy {...props} />}
              onClick={show}
            />

            <NavItem
              label="Logout"
              renderIcon={(props) => <SignOut {...props} />}
              onClick={onLogOutItemClick}
            />
          </div>
        </SheetContent>
      </Sheet>
      <LogOutModal isOpen={isLogOutOpen} setIsOpen={setIsLogOutOpen} />
    </>
  );
}
