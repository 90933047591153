import { UsersThree } from "@phosphor-icons/react";
import React from "react";

import Skeleton from "common/components/ui/Skeleton";
import { Tag } from "common/components/ui/Tag";
import { formatNumber } from "common/helpers/utils";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

interface DripHeaderProps {
  isLoading: boolean;
  totalLeads: number;
  resetFiltersButton: React.ReactNode;
}

export default function DripHeader({
  isLoading,
  totalLeads,
  resetFiltersButton,
}: DripHeaderProps) {
  const isDesktop = useTwBreakpoint("lg");

  const totalLeadsTag = isLoading ? (
    <Skeleton className="h-7 w-16" />
  ) : (
    <Tag size="lg" variant="quaternary" leftIcon={<UsersThree weight="fill" />}>
      {formatNumber(totalLeads)}
    </Tag>
  );

  return (
    <section className="mb-4 flex items-center justify-between gap-4">
      <div>
        <h3 className="text-headline-xl-bold">Targeting Setup</h3>
        <p className="text-black-500">
          Select your campaign targets using filters and a search keyword
        </p>
      </div>

      {isDesktop ? (
        totalLeadsTag
      ) : (
        <div className="flex flex-col items-end gap-y-3">
          {totalLeadsTag}
          {resetFiltersButton}
        </div>
      )}
    </section>
  );
}
