import React, { useState } from "react";

import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import { Lead } from "common/types";

import useRecentLeads from "../../datahooks/useRecentLeads";
import RecentLeadsItem from "./RecentLeadsItem";
import RecentLeadsItemLoader from "./RecentLeadsItemLoader";
import LeadPreview from "../LeadPreview";

import LeadsImage from "assets/images/empty-placeholders/no-profiles.png";
import DripImage from "assets/images/empty-placeholders/messages.png";

interface RecentLeadsProps {
  accountIds: string[];
  isDrip?: boolean;
  flowId?: string;
  campaignId?: string;
  showFlowOrigin?: boolean;
  showCampaignOrigin?: boolean;
}

export default function RecentLeads({
  accountIds,
  isDrip = false,
  flowId = null,
  campaignId = null,
  showFlowOrigin = false,
  showCampaignOrigin = false,
}: RecentLeadsProps) {
  const [selectedLead, setSelectedLead] = useState<Lead>(null);
  const [isLeadPreviewOpen, setIsLeadPreviewOpen] = useState(false);

  const {
    recentLeads,
    isLoadingRecentLeads,
    recentLeadsError,
    refetchRecentLeads,
  } = useRecentLeads({ flowId, campaignId, accountIds });

  let mainContent;
  if (recentLeadsError) {
    mainContent = (
      <ErrorPlaceholder
        errorMessage="Could not display recent Leads"
        onRetry={refetchRecentLeads}
      />
    );
  } else if (isLoadingRecentLeads) {
    mainContent = <RecentLeadsItemLoader />;
  } else if (recentLeads && recentLeads.length) {
    mainContent = (
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3">
        {recentLeads.map((lead) => {
          const { target, target_id: targetId } = lead;

          return (
            <RecentLeadsItem
              key={targetId}
              lead={lead}
              showFlowOrigin={showFlowOrigin}
              showCampaignOrigin={showCampaignOrigin}
              onLeadSelect={() => {
                setSelectedLead(target);
                setIsLeadPreviewOpen(true);
              }}
            />
          );
        })}

        <LeadPreview
          lead={selectedLead}
          isOpen={isLeadPreviewOpen && !!selectedLead}
          onClose={() => setIsLeadPreviewOpen(false)}
        />
      </div>
    );
  } else {
    let emptySubtitle =
      "You do not have any recent leads at this time. Make sure you have at least one active Aimfox campaign";

    if (flowId || campaignId) {
      emptySubtitle = `It looks like you did not get any recent ${isDrip ? "replies" : "leads"} from this ${flowId ? "flow" : "campaign"}. Make sure the campaign is active, and they will show up in no time`;
    }

    mainContent = (
      <EmptyPlaceholder
        imageSrc={isDrip ? DripImage : LeadsImage}
        title={`No recent ${isDrip ? "replies" : "leads"}`}
        subtitle={emptySubtitle}
      />
    );
  }

  let subtitleSuffix = "Aimfox";

  if (flowId) {
    subtitleSuffix = "this flow";
  } else if (campaignId) {
    subtitleSuffix = "this campaign";
  }

  return (
    <section className="my-8">
      <h3 className="mb-1 text-headline-xl-bold">
        Recent {isDrip ? "replies" : "leads"}
      </h3>
      <p className="mb-4 text-black-500">
        The latest {isDrip ? "responses" : "leads"} you have gained through{" "}
        {subtitleSuffix}
      </p>

      {mainContent}
    </section>
  );
}
