import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useCampaignContext } from "common/helpers/CampaignContext";
import RenderIf from "common/components/RenderIf";

import PrimaryFlows from "./PrimaryFlows";
import InMailOptimization from "./InMailOptimization";
import { CampaignFlow, AudienceStats } from "../../types";
import { getFlowsByType, validateFlows } from "../../utils";
import {
  CampaignFlowsErrorProvider,
  useCampaignFlowsErrorContext,
} from "../../context/CampaignFlowsErrorContext";
import Stepper from "./Stepper";

interface CampaignFlowsProps {
  audienceStats: AudienceStats;
  isLoadingAudienceStats: boolean;
}

function CampaignFlowsContent({
  audienceStats,
  isLoadingAudienceStats,
}: CampaignFlowsProps) {
  const {
    type,
    outreach_type: outreachType,
    inmail_optimization: isInMailOptimizationEnabled,
    flows,
    target_count: targetCount,
  } = useCampaignContext();

  const navigate = useNavigate();
  const { setInvalidFlows } = useCampaignFlowsErrorContext();

  const { primaryFlows, inmailFlow } = getFlowsByType(flows);

  let inmailFlowWithTemplate = inmailFlow;
  if (inmailFlow && !inmailFlow.template) {
    inmailFlowWithTemplate = {
      ...inmailFlow,
      template: {
        ai: false,
        edited: false,
        message: "",
        original_id: null,
        subject: "",
        type: "INMAIL_TEMPLATE",
      },
    };
  }

  function goToNextStep() {
    const { isFlowListValid, flowsWithError } = validateFlows(
      flows,
      isInMailOptimizationEnabled,
    );

    setInvalidFlows(flowsWithError);

    if (isFlowListValid) {
      navigate("../schedule");
    } else {
      toast.error("All campaign flows must be valid");
    }
  }

  return (
    <>
      {/* Flows */}
      <>
        {outreachType !== "inmail" && (
          <PrimaryFlows
            flows={primaryFlows}
            type={outreachType}
            targetCount={targetCount}
          />
        )}

        <RenderIf condition={type !== "drip"}>
          <InMailOptimization
            flow={inmailFlowWithTemplate}
            canToggle={outreachType !== "inmail"}
            isEnabled={isInMailOptimizationEnabled}
            isLoadingAudienceStats={isLoadingAudienceStats}
            targetCount={audienceStats ? audienceStats.inmailOptimization : 0}
          />
        </RenderIf>
      </>

      {/* Stepper */}
      <Stepper
        selectedIndex={2}
        goToNextStep={goToNextStep}
        isNextStepDisabled={isLoadingAudienceStats}
      />
    </>
  );
}

export default function CampaignFlows({
  audienceStats,
  isLoadingAudienceStats,
}: CampaignFlowsProps) {
  const [invalidFlows, setInvalidFlows] = useState<CampaignFlow[]>([]);

  return (
    <CampaignFlowsErrorProvider value={{ invalidFlows, setInvalidFlows }}>
      <CampaignFlowsContent
        audienceStats={audienceStats}
        isLoadingAudienceStats={isLoadingAudienceStats}
      />
    </CampaignFlowsErrorProvider>
  );
}
