import React from "react";
import { UsersThree } from "@phosphor-icons/react";

import Skeleton from "common/components/ui/Skeleton";
import { Tag } from "common/components/ui/Tag";
import useWorkspaceLabels from "common/datahooks/useWorkspaceLabels";

import { DripTargeting } from "../../../types";
import DripFilters from "./DripFilters";

interface DripTargetingLabelsProps {
  targeting: DripTargeting;
  isLoading?: boolean;
}

export default function DripTargetingLabels({
  targeting,
  isLoading = false,
}: DripTargetingLabelsProps) {
  const { workspaceLabels, isLoadingWorkspaceLabels } = useWorkspaceLabels();

  if (isLoading || isLoadingWorkspaceLabels) {
    return (
      <div className="flex flex-wrap gap-1.5">
        <Skeleton className="h-7 w-32" />
        <Skeleton className="h-7 w-32" />
      </div>
    );
  }

  const isEmptyTargeting = Object.values(targeting).every((filter) =>
    Array.isArray(filter) ? !filter.length : !filter,
  );

  if (isEmptyTargeting)
    return (
      <Tag
        size="lg"
        variant="primary-black"
        leftIcon={<UsersThree weight="fill" />}
      >
        All leads
      </Tag>
    );

  return <DripFilters targeting={targeting} labels={workspaceLabels} />;
}
