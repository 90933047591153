import React, { useState } from "react";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { useForm } from "react-hook-form";
import { Info } from "@phosphor-icons/react";
import { produce } from "immer";

import { LinkedinPostSchema } from "common/schemas";
import { Input } from "common/components/ui/Input";

import useTargetingMutations from "../../../../datahooks/useTargetingMutations";
import { PostStatus } from "../../../../types";
import Stepper from "../../Stepper";
import PostDetails from "./PostDetails";

import PostCampaignImage from "assets/images/post-campaign.png";

export default function Post() {
  const [postId, setPostId] = useState("");
  const [postStatus, setPostStatus] = useState<PostStatus>({
    isLiked: true,
    isCommented: true,
    isShared: true,
  });

  const { setTargeting, isSettingTargeting } = useTargetingMutations();

  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { postUrl: "" },
    resolver: valibotResolver(LinkedinPostSchema),
  });

  function updatePostStatus(status: keyof PostStatus, checked: boolean) {
    setPostStatus(
      produce((draft) => {
        draft[status] = checked;
      }),
    );
  }

  // Extract post ID from URL
  // TODO - Double-check and possibly refactor regexes according to edge cases
  function extractPostId(url: string): string {
    const postIdMatch = url.match(/\d{19}/);

    if (postIdMatch) {
      return postIdMatch[0];
    }

    throw new Error("Invalid LinkedIn post URL format");
  }

  function onInputChange({ postUrl }: { postUrl: string }) {
    // Show post preview if post exists
    setPostId("");
    const extractedId = extractPostId(postUrl);

    setPostId(extractedId);
  }

  function resetPostUrl() {
    // Reset postUrl state
    reset({ postUrl: "" });

    // Hide post preview
    setPostId("");

    // Reset interactions to false
    setPostStatus({
      isLiked: true,
      isCommented: true,
      isShared: true,
    });
  }

  function goToNextStep() {
    const { isLiked, isCommented, isShared } = postStatus;

    const postDetails = {
      post_id: postId,
      likes: isLiked,
      comments: isCommented,
      reposts: isShared,
    };

    setTargeting({ campaignTargeting: postDetails });
  }

  return (
    <>
      <h2 className="mb-1 mt-4 text-headline-xl-bold">Post search</h2>
      <p className="mb-4 text-black-500 md:mb-10">
        LinkedIn Post campaigns let you target users that have engaged with
        specific LinkedIn posts. They are a great way to find a specific
        audience based on their recent interests and preferred type of content
      </p>

      {postId ? (
        <PostDetails
          postStatus={postStatus}
          postId={postId}
          resetPostUrl={resetPostUrl}
          updatePostStatus={updatePostStatus}
        />
      ) : (
        <section className="mx-auto w-fit rounded-3xl md:border md:border-black-200 md:p-6 md:shadow-md">
          <img
            className="mb-8 rounded-20"
            src={PostCampaignImage}
            alt="Post Campaign"
            width={750}
            height={210}
          />
          <Input
            variant="lg"
            placeholder="Enter post URL here"
            className="w-full"
            error={errors?.postUrl?.message}
            onInput={(e) => {
              setValue("postUrl", e.currentTarget.value);
              handleSubmit(onInputChange)();
            }}
            {...register("postUrl")}
          />
          <div className="mt-1 flex gap-0.5">
            <Info weight="fill" className="fill-black-700" size={16} />
            <span className="text-caption-12-regular text-black-700">
              You can find the URL by clicking the 3 dots at the top of the post
              in your LinkedIn Feed
            </span>
          </div>
        </section>
      )}

      <Stepper
        selectedIndex={0}
        goToNextStep={goToNextStep}
        isNextButtonLoading={isSettingTargeting}
        isNextStepDisabled={!postId}
      />
    </>
  );
}
