import languages from "common/constants/languages";
import { TimezoneOption, timezones } from "common/constants/timezones";
import {
  DripFilter,
  LinkedInProfile,
  Schedule,
  ScheduleInterval,
} from "common/types";

import {
  CampaignFlow,
  CampaignFlowType,
  DripFilters,
  DripTargeting,
  Timezone,
} from "./types";

export function getLanguageName(code: string) {
  return languages.find((lang) => lang.code === code)?.name;
}
export function formattedTimezone(timezone: Timezone): TimezoneOption {
  if (!timezone.name) {
    return timezones[0];
  }

  return timezones.find(
    (timezoneOption) => timezoneOption.label === timezone.name,
  );
}

export function getFlowsByType(flows: CampaignFlow[]) {
  const primaryFlows = flows.filter(
    (flow) =>
      flow.type === "PRIMARY_CONNECT" || flow.type === "PRIMARY_MESSAGE",
  );

  const connectFlow: CampaignFlow = null;

  const inmailFlow = flows.find((flow) => flow.type === "INMAIL_OPTIMIZATION");

  return { primaryFlows, connectFlow, inmailFlow };
}

export function getSortedAndFilteredFlows(
  flows: CampaignFlow[],
  isInMailOptimizationEnabled: boolean,
): CampaignFlow[] {
  if (!flows) return;

  const filteredFlows = flows.filter((flow) => {
    if (!isInMailOptimizationEnabled && flow.type === "INMAIL_OPTIMIZATION") {
      return false;
    }
    return true;
  });

  const { primaryFlows, connectFlow, inmailFlow } =
    getFlowsByType(filteredFlows);

  const sortedAndFilteredFlows = [
    ...primaryFlows,
    connectFlow,
    inmailFlow,
  ].filter((flow) => flow);

  return sortedAndFilteredFlows;
}

export function validateFlows(
  flows: CampaignFlow[],
  isInMailOptimizationEnabled: boolean,
) {
  const flowsWithError: CampaignFlow[] = [];
  const sortedAndFilteredFlows = getSortedAndFilteredFlows(
    flows,
    isInMailOptimizationEnabled,
  );

  sortedAndFilteredFlows.forEach((flow) => {
    if (
      (flow.type === "INMAIL_OPTIMIZATION" && !flow.template?.original_id) ||
      (flow.type === "PRIMARY_MESSAGE" &&
        !flow.flow_message_templates.some((message) => message.original_id))
    ) {
      flowsWithError.push(flow);
    }
  });

  const isFlowListValid = !flowsWithError.length;

  return { flowsWithError, isFlowListValid };
}

export function isValidSchedule(schedule: Schedule) {
  return Object.values(schedule).some(({ intervals }) => intervals.length > 0);
}

export function getFormattedInterval({ start, end }: ScheduleInterval) {
  const startValue = `${start <= 9 ? `0${start}` : start}:00`;

  let endValue;
  if (end === 24) {
    endValue = "23:59";
  } else {
    endValue = `${end <= 9 ? `0${end}` : end}:00`;
  }

  return { startValue, endValue };
}

export function getFlowTitle(flow: CampaignFlow) {
  const flowHeadingOptions: Record<CampaignFlowType, string> = {
    CONNECT_OPTIMIZATION: "Connect Optimization",
    INMAIL_OPTIMIZATION: "Free InMail",
    PRIMARY_MESSAGE: flow.name,
    PRIMARY_CONNECT: flow.name,
  };

  return flowHeadingOptions[flow.type];
}

export function exportProfilesToCSV(profiles: LinkedInProfile[]) {
  const columns = ["id", "full_name", "occupation", "location", "profile_url"];

  let csvContent = `${columns.join(",")}\n`;

  profiles.forEach((profile) => {
    // Wrap row in quotes to handle commas
    const row = [
      `"${profile.id}"`,
      `"${profile.full_name}"`,
      `"${profile.occupation}"`,
      `${profile.location ? `"${profile.location.name}"` : ""}`,
      `https://linkedin.com/in/${profile.public_identifier}`,
    ].join(",");

    csvContent += `${row}\n`;
  });

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const downloadUrl = URL.createObjectURL(blob);

  return downloadUrl;
}

// returns parsed filters for server requests
export function parseDripFilters(filters: DripFilters): DripTargeting {
  const { keywords, labels, locations, ...rest } = filters;

  const modifiedLocations = locations.flatMap((location) => {
    const ids = Array.isArray(location.id) ? location.id : [location.id];

    return ids.map((id) => ({
      id,
      name: location.name,
    }));
  });

  return {
    keywords,
    labels: labels as DripFilter[],
    locations: modifiedLocations,
    ...(rest as Omit<DripTargeting, "locations" | "labels" | "keywords">),
  };
}
