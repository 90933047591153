import React, { useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { clsx } from "clsx";

import SearchInput from "common/components/SearchInput";
import Checkbox from "common/components/ui/Checkbox";
import { FacetParam, Label } from "common/types";
import { labelColors } from "common/constants";
import useSortedFacets from "common/hooks/useSortedFacets";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import { useDripFiltersContext } from "../DripFiltersContext";
import { DripTargeting } from "../../../../../types";

interface DripFacetProps {
  allFacets: FacetParam[];
  label: string;
  searchKey: keyof Omit<DripTargeting, "keywords" | "favorite">;
  isLoading: boolean;
  labels: Label[];
}

function FacetValue({
  searchKey,
  facet,
  labels,
}: {
  searchKey: keyof Omit<DripTargeting, "keywords" | "favorite">;
  facet: FacetParam;
  labels: Label[];
}) {
  const { id, name } = facet;

  if (searchKey === "labels") {
    const { color } = labels.find(({ id: labelId }) => labelId === id);

    return (
      <>
        <div
          className={clsx(
            "ml-2 size-3 shrink-0 rounded",
            labelColors[color].bgClassName,
          )}
        />
        <span className="truncate text-start text-button-14">{name}</span>
      </>
    );
  }

  return <span className="truncate text-start text-button-14">{name}</span>;
}

export default function DripFacet({
  allFacets,
  label,
  searchKey,
  isLoading,
  labels,
}: DripFacetProps) {
  const isDesktop = useTwBreakpoint("lg");
  const [inputValue, setInputValue] = useState("");
  const { filters, setFilters } = useDripFiltersContext();

  const selectedFacets = filters[searchKey];
  const sortedFacets = useSortedFacets(allFacets, selectedFacets);
  const sortedAndFilteredFacets = sortedFacets.filter((facet) => {
    if (inputValue) {
      return facet.name.toLowerCase().includes(inputValue.toLowerCase());
    }

    return facet;
  });

  return (
    <>
      <SearchInput
        className="max-lg:mb-2 lg:mx-2 lg:my-1"
        variant={isDesktop ? "sm" : "md"}
        value={inputValue}
        placeholder={`Search ${label}`}
        onChange={(e) => setInputValue(e.currentTarget.value)}
        onClear={() => setInputValue("")}
      />

      {sortedAndFilteredFacets.length ? (
        <Virtuoso
          style={{
            height: `min(${sortedAndFilteredFacets.length * 36}px, 220px)`,
          }}
          className="scrollbar-thin"
          totalCount={sortedAndFilteredFacets.length}
          // eslint-disable-next-line react/no-unstable-nested-components
          itemContent={(index) => {
            const facet = sortedAndFilteredFacets[index];
            const { name, id, target_count: targetCount } = facet;

            const isSelected = selectedFacets.some((selected) =>
              Array.isArray(selected.id) && Array.isArray(id)
                ? selected.id.every((string) => id.includes(string))
                : selected.id === id,
            );

            function toggleSelection() {
              if (isSelected) {
                setFilters({
                  [searchKey]: selectedFacets.filter((selected) =>
                    Array.isArray(id) && Array.isArray(selected.id)
                      ? !id.every((string) => selected.id.includes(string))
                      : id !== selected.id,
                  ),
                });
              } else {
                setFilters({
                  [searchKey]: [...selectedFacets, { id, name }],
                });
              }
            }

            return (
              <button
                type="button"
                key={name}
                onClick={toggleSelection}
                disabled={isLoading && !isSelected}
                className="flex w-full items-center gap-x-1.5 bg-white py-2 disabled:opacity-30 lg:px-4 lg:transition-colors lg:hover:bg-black-50"
              >
                <Checkbox checked={isSelected} isStyleOnly />

                <FacetValue
                  facet={facet}
                  searchKey={searchKey}
                  labels={labels}
                />

                <span className="ml-auto text-black-400">{targetCount}</span>
              </button>
            );
          }}
        />
      ) : (
        <p className="p-4 text-center text-black-500">No results found</p>
      )}
    </>
  );
}
