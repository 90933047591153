import React, { useLayoutEffect, useState } from "react";

import { GenericSearchParam } from "common/types";
import useDebounce from "common/hooks/useDebounce";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import SearchInput from "common/components/SearchInput";

import SearchParamOption from "./SearchParamOption";
import useMergeSearchOptions from "../useMergeSearchOptions";
import useSearchOptions from "../../../../../../datahooks/useSearchOptions";
import useSearchParams from "../useSearchParams";
import { useSearchParamsContext } from "../../SearchParamsContext";
import { profileSearchOptions } from "../../../../../../constants";
import MobileOptions from "./MobileOptions";

export default function GenericOptions({
  optionsKey,
}: {
  optionsKey: keyof typeof profileSearchOptions;
}) {
  const isTabletOrDesktop = useTwBreakpoint("lg");
  const [inputValue, setInputValue] = useState("");
  const debouncedValue = useDebounce(inputValue, 300);
  const { searchParams } = useSearchParamsContext();
  const {
    addSearchParamValue,
    removeSearchParamValue,
    appliedSearchParamValues,
  } = useSearchParams(optionsKey);
  const { suggestions, ...searchOptions } = useSearchOptions({
    searchParams,
    [`${optionsKey}Keywords`]: debouncedValue,
  });
  const { mergeOptions, allOptions } =
    useMergeSearchOptions<GenericSearchParam>();

  const options = searchOptions[optionsKey];
  const { searchParamKey, label } = profileSearchOptions[optionsKey];

  const urnsInAppliedSearchParamValues = new Set(
    appliedSearchParamValues.map((option) => option.urn),
  );
  const filteredOptions = allOptions.filter(
    (suggestion) => !urnsInAppliedSearchParamValues.has(suggestion.urn),
  );

  useLayoutEffect(() => {
    if (options || suggestions) {
      mergeOptions(
        appliedSearchParamValues as GenericSearchParam[],
        options,
        suggestions[searchParamKey],
      );
    }
  }, [suggestions, options]);

  function getContent() {
    if (!isTabletOrDesktop && filteredOptions?.length)
      return (
        <MobileOptions
          options={filteredOptions}
          appliedOptions={appliedSearchParamValues as GenericSearchParam[]}
          removeSearchParam={(option) =>
            removeSearchParamValue(searchParamKey, option)
          }
          addSearchParam={(option) =>
            addSearchParamValue(searchParamKey, option)
          }
        />
      );

    if (isTabletOrDesktop && allOptions?.length)
      return (
        <div className="my-1 flex max-h-60 flex-col gap-1 overflow-y-auto scrollbar-thin">
          {allOptions.map((option) => {
            const isSelected = !!appliedSearchParamValues.find(
              ({ urn }) => urn === option.urn,
            );
            return (
              <SearchParamOption
                key={option.urn}
                isSelected={isSelected}
                onClick={
                  isSelected
                    ? () => removeSearchParamValue(searchParamKey, option)
                    : () => addSearchParamValue(searchParamKey, option)
                }
                option={option}
              />
            );
          })}
        </div>
      );

    return (
      <p className="mx-4 break-words py-8 text-center text-black-500">
        No results found for{" "}
        <span className="text-black-950">{debouncedValue}</span>
      </p>
    );
  }

  return (
    <>
      <SearchInput
        variant={isTabletOrDesktop ? "sm" : "md"}
        value={inputValue}
        className="max-lg:w-full lg:mx-2 lg:mt-2"
        placeholder={`Search ${label}`}
        onChange={(e) => setInputValue(e.currentTarget.value)}
        onClear={() => setInputValue("")}
      />
      {getContent()}
    </>
  );
}
