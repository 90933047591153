import React, { useLayoutEffect, useState } from "react";
import { Briefcase, ClockCounterClockwise } from "@phosphor-icons/react";

import useDebounce from "common/hooks/useDebounce";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "common/components/ui/Tabs";
import { typedObjectEntries, typedObjectKeys } from "common/helpers/utils";
import { SearchParamWithImage } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import SearchInput from "common/components/SearchInput";

import { profileSearchOptions } from "../../../../../../constants";
import useSearchParams from "../useSearchParams";
import useSearchOptions from "../../../../../../datahooks/useSearchOptions";
import useMergeSearchOptions from "../useMergeSearchOptions";
import SearchParamOption from "./SearchParamOption";
import { useSearchParamsContext } from "../../SearchParamsContext";
import MobileOptions from "./MobileOptions";

const categories = {
  current_companies: "Current Companies",
  past_companies: "Past Companies",
};

export default function Companies({
  optionsKey,
}: {
  optionsKey: keyof typeof profileSearchOptions;
}) {
  const isTabletOrDesktop = useTwBreakpoint("lg");
  const { searchParams } = useSearchParamsContext();

  const [selectedTab, setSelectedTab] = useState(
    typedObjectKeys(categories)[0],
  );
  const [inputValue, setInputValue] = useState("");
  const debouncedValue = useDebounce(inputValue, 300);

  const { addSearchParamValue, removeSearchParamValue } =
    useSearchParams(optionsKey);
  const { companies, suggestions } = useSearchOptions({
    searchParams,
    companiesKeywords: debouncedValue,
  });
  const { mergeOptions, allOptions } =
    useMergeSearchOptions<SearchParamWithImage>();

  const appliedSearchParamValues = searchParams[selectedTab];

  useLayoutEffect(() => {
    if (companies || suggestions) {
      mergeOptions(
        appliedSearchParamValues,
        companies,
        suggestions[selectedTab],
      );
    }
  }, [suggestions, companies, selectedTab]);

  useLayoutEffect(() => {
    setInputValue("");
  }, [selectedTab]);

  const excludedUrns = new Set(
    searchParams[
      selectedTab === "current_companies"
        ? "past_companies"
        : "current_companies"
    ].map((company) => company.urn),
  );

  const urnsInAppliedSearchParamValues = new Set(
    appliedSearchParamValues.map((option) => option.urn),
  );

  const filteredOptions = allOptions.filter(({ urn }) =>
    isTabletOrDesktop
      ? !excludedUrns.has(urn)
      : !urnsInAppliedSearchParamValues.has(urn),
  );

  function getContent() {
    if (!isTabletOrDesktop && filteredOptions?.length)
      return (
        <MobileOptions
          options={filteredOptions}
          appliedOptions={appliedSearchParamValues}
          removeSearchParam={(option) =>
            removeSearchParamValue(selectedTab, option)
          }
          addSearchParam={(option) => addSearchParamValue(selectedTab, option)}
        />
      );

    if (isTabletOrDesktop && allOptions?.length)
      return (
        <div className="my-1 flex max-h-72 flex-col overflow-y-auto scrollbar-thin">
          {filteredOptions.map((option) => {
            const isSelected = !!appliedSearchParamValues.find(
              ({ urn }) => urn === option.urn,
            );
            return (
              <SearchParamOption
                key={option.urn}
                isSelected={isSelected}
                onClick={
                  isSelected
                    ? () => removeSearchParamValue(selectedTab, option)
                    : () => addSearchParamValue(selectedTab, option)
                }
                option={option}
              />
            );
          })}
        </div>
      );

    return (
      <p className="mx-4 break-words py-8 text-center text-black-500">
        No results found for{" "}
        <span className="text-black-950">{debouncedValue}</span>
      </p>
    );
  }

  return (
    <Tabs
      value={selectedTab}
      onValueChange={(value) =>
        setSelectedTab(value as keyof typeof categories)
      }
    >
      <TabsList size="xs" className="pb-1 lg:mt-2 lg:px-4">
        <TabsTrigger className="flex-1" value={typedObjectKeys(categories)[0]}>
          <Briefcase className="-ml-2" /> Current
        </TabsTrigger>
        <TabsTrigger className="flex-1" value={typedObjectKeys(categories)[1]}>
          <ClockCounterClockwise className="-ml-2" />
          Past
        </TabsTrigger>
      </TabsList>

      {typedObjectEntries(categories).map(([key, label]) => (
        <TabsContent key={key} value={key} className="flex flex-col gap-1">
          <SearchInput
            variant="sm"
            value={inputValue}
            className="mx-2 mt-2"
            placeholder={`Search ${label}`}
            onChange={(e) => setInputValue(e.currentTarget.value)}
            onClear={() => setInputValue("")}
          />

          {getContent()}
        </TabsContent>
      ))}
    </Tabs>
  );
}
