import React, { useState, JSX } from "react";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import Checkbox from "common/components/ui/Checkbox";
import SearchInput from "common/components/SearchInput";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import RenderIf from "common/components/RenderIf";
import { FacetParam, SelectedFacetParam } from "common/types";
import useSortedFacets from "common/hooks/useSortedFacets";

const initialVisibleCount = 10;

export default function SearchFacet({
  label,
  allFacets,
  selectedFacets,
  toggleSearchParam,
  isLoading,
  renderFacet,
}: {
  label: string;
  allFacets: FacetParam[];
  selectedFacets: SelectedFacetParam[];
  toggleSearchParam: (facetParam: SelectedFacetParam) => void;
  isLoading: boolean;
  renderFacet: (facetParam: FacetParam) => JSX.Element;
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const sortedFacets = useSortedFacets(allFacets, selectedFacets);

  const filteredFacets = sortedFacets.filter(
    ({ name }, index) =>
      !searchValue ||
      index < selectedFacets.length ||
      name.toLowerCase().includes(searchValue.toLowerCase()),
  );
  const shownOptions = isExpanded
    ? filteredFacets
    : filteredFacets.slice(0, initialVisibleCount);

  const hasNoSearchResults = !shownOptions.length && searchValue;

  return (
    <>
      <SearchInput
        variant={isTabletOrDesktop ? "md" : "lg"}
        className="mb-2"
        placeholder={`Search ${label}`}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onClear={() => setSearchValue("")}
      />

      {hasNoSearchResults ? (
        <p className="break-words p-4 text-center text-black-500">
          No results for <span className="text-black-950">{searchValue}</span>
        </p>
      ) : (
        shownOptions.map((facetParam) => {
          const { target_count: targetCount, id } = facetParam;

          const isSelected = selectedFacets.some((selectedFacet) =>
            Array.isArray(selectedFacet.id) && Array.isArray(id)
              ? selectedFacet.id.every((string) => id.includes(string))
              : selectedFacet.id === id,
          );

          const isDisabled = isLoading && !isSelected;

          return (
            <button
              type="button"
              key={id.toString()}
              onClick={() => toggleSearchParam(facetParam)}
              disabled={isDisabled}
              className="flex items-center gap-x-1.5 p-2 disabled:opacity-30"
            >
              <Checkbox checked={isSelected} isStyleOnly />

              {renderFacet(facetParam)}

              <span className="ml-auto text-black-400">{targetCount}</span>
            </button>
          );
        })
      )}
      <RenderIf condition={sortedFacets.length > initialVisibleCount}>
        {isExpanded ? (
          <Button
            className="mt-2"
            variant="quaternary-black"
            size="sm"
            onClick={() => setIsExpanded(false)}
            leftIcon={<CaretLeft />}
          >
            Show less
          </Button>
        ) : (
          <Button
            className="mt-2"
            variant="quaternary-black"
            size="sm"
            onClick={() => setIsExpanded(true)}
            rightIcon={<CaretRight />}
          >
            Show all ({filteredFacets.length})
          </Button>
        )}
      </RenderIf>
    </>
  );
}
