import React, { useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useIntercom } from "react-use-intercom";
import Hotjar from "@hotjar/browser";
import { useUserback } from "@userback/react";
import mixpanel from "mixpanel-browser";

import useGTM from "common/hooks/useGTM";
import { isProductionEnvironment } from "common/helpers/utils";
import checkAuth from "common/helpers/checkAuth";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import WorkspaceLayout from "common/layouts/WorkspaceLayout";
import BaseLayout from "common/layouts/BaseLayout";
import useWorkspaces from "common/datahooks/useWorkspaces";
import usePricing from "common/datahooks/usePricing";
import useCello from "common/hooks/useCello";

import useCustomer from "./common/datahooks/useCustomer";
import useCustomerIO from "./common/hooks/useCustomerIO";
import CrashPage from "./common/components/CrashPage";
import { baseRoutes, workspaceRoutes } from "./pages/routes";
import Onboarding from "./pages/onboarding/components/Onboarding";

function App() {
  const isDesktop = useTwBreakpoint("lg");
  const signupRef = useRef(false);
  const [isAuthLoaded, setIsAuthLoaded] = useState(false);
  const { customerInfo } = useCustomer();
  const { workspaces } = useWorkspaces();
  const { pricing } = usePricing();
  const { initializeCustomerIO } = useCustomerIO();
  const { sendEvent } = useGTM();
  const { boot } = useIntercom();
  const [searchParams] = useSearchParams();
  const {
    identify,
    setName,
    setEmail,
    isLoaded: isUserbackLoaded,
  } = useUserback();
  const { initializeCello, isReady: isCelloReady } = useCello();

  useEffect(() => {
    if (!!customerInfo && isAuthLoaded && isCelloReady) {
      const {
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        chargebee_id: chargebeeId,
      } = customerInfo;

      const fullName = `${firstName} ${lastName}`;

      boot({
        email,
        name: fullName,
        phone,
        hideDefaultLauncher:
          !isDesktop || import.meta.env.MODE === "development",
      });

      initializeCustomerIO(chargebeeId, customerInfo.email);

      // Only initialize Cello when it's ready
      try {
        initializeCello(customerInfo);
      } catch (error) {
        // Log error but don't break the app
        // eslint-disable-next-line no-console
        console.error("Failed to initialize Cello:", error);
      }

      if (isProductionEnvironment()) {
        // Sentry
        Sentry.setTag("keycloak id", customerInfo.id);

        Sentry.setUser({
          id: chargebeeId,
          email,
          first_name: firstName,
          last_name: lastName,
        });

        // Hotjar
        Hotjar.identify(chargebeeId, {
          email,
          name: fullName,
        });

        // Userback
        if (isUserbackLoaded) {
          identify(chargebeeId, {
            name: fullName,
            email,
          });

          setName(fullName);
          setEmail(email);
        }

        // Mixpanel
        mixpanel.identify(chargebeeId);

        mixpanel.people.set({
          $email: email,
          $name: fullName,
        });
      }
    }
  }, [isAuthLoaded, customerInfo, isUserbackLoaded, isCelloReady]);

  useEffect(() => {
    if (searchParams.get("signup")) {
      signupRef.current = true;
    }

    checkAuth()
      .then(() => {
        setIsAuthLoaded(true);
      })
      // Catch promise rejection to avoid Sentry logging errors
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (customerInfo && signupRef.current) {
      sendEvent("signed_up", ({ email }) => ({ email }));
    }
  }, [customerInfo]);

  useEffect(() => {
    if (workspaces && customerInfo && pricing) {
      document.getElementById("loader-container")?.remove();
    }
  }, [workspaces, customerInfo, pricing]);

  if (!workspaces || !customerInfo || !pricing) {
    return null;
  }

  return (
    <Sentry.ErrorBoundary
      /* eslint-disable-next-line react/no-unstable-nested-components */
      fallback={({ resetError, eventId }) => (
        <CrashPage resetError={resetError} eventId={eventId} />
      )}
    >
      <Routes>
        {workspaces.length ? (
          <>
            {/* Base Routes */}
            <Route element={<BaseLayout />}>
              {baseRoutes.map(({ component, path }) => (
                <Route key={path} path={path} element={component} />
              ))}
            </Route>

            {/* Workspace routes */}
            <Route path="workspaces/:workspaceId" element={<WorkspaceLayout />}>
              {workspaceRoutes.map(({ path, component }) => (
                <Route key={path} path={path} element={component} />
              ))}
            </Route>

            {/* TODO: Store last visited workspace in Local Storage */}
            <Route
              path="*"
              element={
                <Navigate
                  to={`workspaces/${workspaces[0].id}/${workspaceRoutes[0].path}?${searchParams.toString()}`}
                  replace
                />
              }
            />
          </>
        ) : (
          <>
            <Route path="/onboarding" element={<Onboarding />} />

            <Route
              path="*"
              element={
                <Navigate
                  to={`onboarding?${searchParams.toString()}`}
                  replace
                />
              }
            />
          </>
        )}
      </Routes>
    </Sentry.ErrorBoundary>
  );
}

export default App;
