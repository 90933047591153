import React from "react";
import { PencilSimple, UsersThree } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

import { Tag } from "common/components/ui/Tag";
import { Button } from "common/components/ui/Button";
import { useCampaignContext } from "common/helpers/CampaignContext";

export default function AudienceInfo() {
  const { target_count: targetCount } = useCampaignContext();

  return (
    <section className="mb-4 flex items-center gap-2 rounded-2xl border border-black-200 px-3 py-4 md:px-4">
      <div className="flex flex-col">
        <h4 className="text-button-16">Audience</h4>

        <p className="text-caption-12-regular text-black-500">
          LinkedIn accounts you will target with this campaign
        </p>
      </div>

      <div className="ml-auto flex items-center gap-4">
        <Tag
          size="lg"
          variant="quaternary"
          leftIcon={<UsersThree weight="fill" />}
        >
          {targetCount}
        </Tag>

        <div className="h-10 w-px bg-black-200" />

        <Button intent="iconOnly" variant="secondary-black" asChild>
          <Link to="./audience">
            <PencilSimple />
          </Link>
        </Button>
      </div>
    </section>
  );
}
