import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";

import { post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import {
  Facets,
  Lead,
  LeadsSearchParams,
  SelectedFacetParam,
} from "common/types";
import getQueryKeys from "common/datahooks/getQueryKeys";

import { parseSearchParams } from "../../leads/utils";

async function getDripLeadsRequest(
  workspaceId: string,
  searchParams: LeadsSearchParams<SelectedFacetParam>,
  options: {
    pageSize: number;
    pageParam: number;
  },
): Promise<{
  totalLeads: number;
  hits: Lead[];
  facets: Facets;
  nextPage?: number;
}> {
  const { pageSize, pageParam } = options;

  const queryParams = new URLSearchParams();
  queryParams.set("start", (pageParam * pageSize).toString());
  queryParams.set("count", pageSize.toString());

  const {
    leads: { total_leads: totalLeads, hits, facets },
  } = await post(
    `workspaces/${workspaceId}/leads-search?${queryParams.toString()}`,
    { ...parseSearchParams(searchParams) },
  );

  return {
    hits,
    totalLeads,
    facets,
    nextPage:
      totalLeads > (pageParam + 1) * pageSize ? pageParam + 1 : undefined,
  };
}

export default function useDripLeads(options: {
  searchParams: LeadsSearchParams<SelectedFacetParam>;
  pageSize?: number;
}) {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { searchParams, pageSize = 24 } = options;

  const { campaignsKeys } = getQueryKeys(workspaceId);

  const {
    data,
    hasNextPage: hasMoreDripLeads,
    isFetchingNextPage: isFetchingMoreLeads,
    fetchNextPage: fetchMoreLeads,
    isError: isDripLeadsError,
    refetch: refetchDripLeads,
    isFetching: isFetchingLeads,
  } = useInfiniteQuery({
    queryKey: campaignsKeys.drip(searchParams),
    queryFn: ({ pageParam }) =>
      getDripLeadsRequest(workspaceId, searchParams, {
        pageSize,
        pageParam,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage.nextPage,
    placeholderData: keepPreviousData,
    staleTime: 10 * 60 * 1000, // 10 minutes
    gcTime: 15 * 60 * 1000, // 15 minutes
  });

  return {
    leads: data?.pages.flatMap((page) => page.hits) || [],
    totalLeads: data?.pages[0]?.totalLeads || 0,
    facets: data?.pages[0]?.facets,
    isDripLeadsError,
    refetchDripLeads,
    hasMoreDripLeads,
    isFetchingMoreLeads,
    fetchMoreLeads,
    isFetchingLeads,
  };
}
