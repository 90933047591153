import React from "react";
import clsx from "clsx";
import { CaretDown } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import RenderIf from "common/components/RenderIf";
import { Facets, Label } from "common/types";
import { typedObjectEntries } from "common/helpers/utils";

import { dripFacets } from "../../../../../constants";
import { useDripFiltersContext } from "../DripFiltersContext";
import DripFacet from "./DripFacet";
import FavoriteFilter from "./FavoriteFilter";

interface FiltersProps {
  facets: Facets;
  isLoading: boolean;
  labels: Label[];
}

export default function DesktopFilters({
  facets,
  isLoading,
  labels,
}: FiltersProps) {
  const {
    filters: { keywords, ...dripFilters },
  } = useDripFiltersContext();

  return (
    <section className="mb-4 flex flex-wrap items-center gap-2">
      {typedObjectEntries(dripFacets).map(([key, { icon: Icon, label }]) => {
        const facetKey = key === "education" ? "schools" : key;
        const allFacets = facets ? facets[facetKey] : [];

        const selectedFiltersCount = dripFilters[key].length;

        return (
          <Popover key={key}>
            <PopoverTrigger asChild>
              <Button
                variant={
                  selectedFiltersCount ? "primary-black" : "tertiary-black"
                }
                leftIcon={
                  <Icon weight={key === "origins" ? "regular" : "fill"} />
                }
                rightIcon={
                  <CaretDown className="transition-transform group-data-[state=open]:rotate-180" />
                }
                className={clsx(
                  "group",
                  selectedFiltersCount
                    ? "data-[state=open]:bg-black-700"
                    : "data-[state=open]:bg-black-50",
                )}
              >
                {label}

                <RenderIf condition={!!selectedFiltersCount}>
                  <span className="flex size-6 shrink-0 items-center justify-center rounded-full bg-white text-black-950">
                    {selectedFiltersCount}
                  </span>
                </RenderIf>
              </Button>
            </PopoverTrigger>

            <PopoverContent className="flex w-72 flex-col gap-1 py-2">
              <DripFacet
                allFacets={allFacets}
                label={label}
                searchKey={key}
                isLoading={isLoading}
                labels={labels}
              />
            </PopoverContent>
          </Popover>
        );
      })}
      <FavoriteFilter />
    </section>
  );
}
