import React from "react";

import { Lead } from "common/types";
import { getMonthName } from "common/helpers/utils";
import Skeleton from "common/components/ui/Skeleton";

const aboutValues: { label: string; getValue: (lead: Lead) => string }[] = [
  {
    label: "Birthday",
    getValue: ({ birthday }) =>
      birthday ? `${birthday.day} ${getMonthName(birthday.month - 1)}` : "-",
  },
  { label: "Email", getValue: ({ email }) => email || "-" },
  {
    label: "Location",
    getValue: ({ location }) => (location ? location.name : "-"),
  },
  {
    label: "Phone",
    getValue: ({ phones }) =>
      phones.length ? phones[0].number.toString() : "-",
  },
];
export default function About({
  lead,
  isLoading,
}: {
  lead: Lead;
  isLoading: boolean;
}) {
  return (
    <div className="flex flex-col gap-y-2.5 rounded-20 bg-white p-4">
      <span className="mb-1 text-button-14">About</span>
      {aboutValues.map(({ label, getValue }) => (
        <div className="flex justify-between" key={label}>
          <span className="text-caption-12-regular text-black-400">
            {label}
          </span>
          <span className="text-caption-12-bold text-black-700">
            {isLoading ? <Skeleton className="h-4 w-36" /> : getValue(lead)}
          </span>
        </div>
      ))}
    </div>
  );
}
