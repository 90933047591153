import { useQuery } from "@tanstack/react-query";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { get } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { Targeting } from "../types";

async function getTargetingRequest(
  workspaceId: string,
  campaignId: string,
): Promise<Targeting> {
  return (
    await get(`workspaces/${workspaceId}/campaigns/${campaignId}/targeting`)
  ).targeting;
}

export default function useTargeting() {
  const { id: campaignId } = useCampaignContext();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { campaignsKeys } = getQueryKeys(workspaceId);

  const { data: targeting, isLoading: isLoadingTargeting } = useQuery({
    queryKey: campaignsKeys.targeting(campaignId),
    queryFn: () => getTargetingRequest(workspaceId, campaignId),
  });

  return {
    targeting,
    isLoadingTargeting,
  };
}
