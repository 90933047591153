import { useCallback, useRef, useState } from "react";

export default function useIntersection<T extends HTMLElement>(
  options?: ConstructorParameters<typeof IntersectionObserver>[1],
) {
  const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const ref = useCallback(
    (node: T | null) => {
      if (observer.current) {
        observer.current.disconnect();
        observer.current = null;
      }

      if (node === null) {
        setEntry(null);
        return;
      }

      observer.current = new IntersectionObserver(([_entry]) => {
        setEntry(_entry);
      }, options);

      observer.current.observe(node);
    },
    [options?.rootMargin, options?.root, options?.threshold],
  );

  const isIntersecting = entry ? entry.isIntersecting : false;

  return { ref, entry, isIntersecting };
}
