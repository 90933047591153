import React from "react";
import { Check, Star } from "@phosphor-icons/react";

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "common/components/ui/Accordion";
import { Tag } from "common/components/ui/Tag";
import RenderIf from "common/components/RenderIf";

import { favoriteFilterOptions } from "../../../../../../constants";

interface FavoriteFilterProps {
  isFavorite: boolean;
  setFilter: (filter: boolean) => void;
}

export default function MobileLayout({
  isFavorite,
  setFilter,
}: FavoriteFilterProps) {
  return (
    <AccordionItem value="favorite">
      <AccordionTrigger className="h-14">
        <Star weight="fill" size={20} />
        <span className="line-clamp-1 break-all">Favorite</span>
        <RenderIf condition={isFavorite}>
          <Tag variant="primary-black">1</Tag>
        </RenderIf>
      </AccordionTrigger>
      <AccordionContent>
        {favoriteFilterOptions.map(({ text, value }) => (
          <button
            type="button"
            key={text}
            onClick={() => setFilter(value)}
            className="flex w-full items-center gap-x-1.5 py-2"
          >
            {text}
            {isFavorite === value && <Check className="ml-auto" size={20} />}
          </button>
        ))}
      </AccordionContent>
    </AccordionItem>
  );
}
