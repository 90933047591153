import { useMemo } from "react";
import {
  Check,
  EnvelopeSimpleOpen,
  Icon,
  UserPlus,
  UsersThree,
} from "@phosphor-icons/react";

import { Metrics } from "common/types";
import { useCampaignContext } from "common/helpers/CampaignContext";

import { CampaignFlowType } from "../types";

interface MetricConfig {
  icon: Icon;
  label: string;
  getValue: (metricsSum: Metrics) => string | number;
}

function getFlowTypeMetricsValues(type: CampaignFlowType, isDrip: boolean) {
  const flowTypeMetrics: Record<
    CampaignFlowType,
    {
      sentTitle: string;
      successTitle: string;
      actionsKey: keyof Metrics;
      successCountKey: keyof Metrics;
    }
  > = {
    CONNECT_OPTIMIZATION: {
      sentTitle: "Invites",
      successTitle: "Acceptance",
      actionsKey: "sent_connections",
      successCountKey: "accepted_connections",
    },
    INMAIL_OPTIMIZATION: {
      sentTitle: "InMails",
      successTitle: "Reply",
      actionsKey: "sent_inmails",
      successCountKey: "replies",
    },
    PRIMARY_CONNECT: {
      sentTitle: "Invites",
      successTitle: "Acceptance",
      actionsKey: "sent_connections",
      successCountKey: "accepted_connections",
    },
    PRIMARY_MESSAGE: {
      sentTitle: isDrip ? "Messages" : "Message requests",
      successTitle: "Reply",
      actionsKey: isDrip ? "sent_messages" : "message_requests",
      successCountKey: "replies",
    },
  };

  return flowTypeMetrics[type];
}

export default function useMetricsList(flowType: CampaignFlowType) {
  const { type } = useCampaignContext();
  const isDrip = type === "drip";

  return useMemo(() => {
    const { actionsKey, sentTitle, successCountKey, successTitle } =
      getFlowTypeMetricsValues(flowType, isDrip);

    const metricsList: MetricConfig[] = [
      ...(isDrip
        ? []
        : [
            {
              icon: UsersThree,
              label: "Leads gained",
              getValue: (metricsSum: Metrics) => metricsSum[successCountKey],
            },
          ]),
      {
        icon: flowType === "PRIMARY_CONNECT" ? UserPlus : EnvelopeSimpleOpen,
        label: `${sentTitle} sent`,
        getValue: (metricsSum) => metricsSum[actionsKey],
      },
      {
        icon: Check,
        label: `${successTitle} rate`,
        getValue: (metricsSum: Metrics) => {
          const actionsSent = metricsSum[actionsKey];
          const successCount = metricsSum[successCountKey];

          const percentage = actionsSent
            ? Math.round((successCount / actionsSent) * 100)
            : 0;

          return `${successCount} (${percentage}%)`;
        },
      },
    ];

    return metricsList;
  }, [flowType, isDrip]);
}
