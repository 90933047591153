import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import { Button } from "common/components/ui/Button";
import Label from "common/components/ui/Label";
import { CampaignOutreachType, CampaignType } from "common/types";
import useCampaigns from "common/datahooks/useCampaigns";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import useWorkspaceNavigate from "common/hooks/useWorkspaceNavigate";
import MaxLengthInput from "common/components/MaxLengthInput";
import { getSchemaMaxLength } from "common/helpers/utils";
import {
  CampaignNameSchema,
  CampaignSettingsSchemaType,
  DefaultCampaignSettingsSchema,
  DripCampaignSettingsSchema,
  SearchCampaignSettingsSchema,
} from "common/schemas";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { DialogFooter } from "common/components/ui/Dialog";
import { DrawerFooter } from "common/components/ui/Drawer";
import useAppStore from "common/hooks/useAppStore";

import LinkedInAccountSelect from "./LinkedInAccountSelect";
import OutreachType from "./OutreachType";
// Campaign imports
import useCampaignMutations from "../../../../pages/campaigns/datahooks/useCampaignMutations";
import AudienceSize from "./AudienceSize";

interface CampaignSettingsProps {
  type: CampaignType;
  goBack: () => void;
  resetState: () => void;
}

export default function CampaignSettings({
  type,
  goBack,
  resetState,
}: CampaignSettingsProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const { relativeNavigate } = useWorkspaceNavigate();

  const { accounts } = useSelectedWorkspaceContext();
  const loggedInAccounts = accounts.filter(
    (account) => account.state === "LoggedIn",
  );

  const { campaigns } = useCampaigns();
  const { createCampaign, isCreatingCampaign } = useCampaignMutations();

  // Local state
  const [selectedOutreachType, setSelectedOutreachType] =
    useState<CampaignOutreachType>(type === "drip" ? "message" : "connect");
  const [selectedAccount, setSelectedAccount] = useState(loggedInAccounts[0]);

  const setIsCampaignModalOpen = useAppStore(
    (state) => state.setIsCampaignModalOpen,
  );

  function onSubmit({ name, size }: { name: string; size: number }) {
    createCampaign({
      name,
      type,
      ...(type === "drip"
        ? { inmail_optimization: false }
        : { audience_size: size }),
      outreach_type: selectedOutreachType,
      account_id: selectedAccount.id,
    }).then((campaign) => {
      setIsCampaignModalOpen(false);
      resetState();
      relativeNavigate(`campaigns/${campaign.id}`);
    });
  }

  const defaultName = `${type.charAt(0).toUpperCase() + type.slice(1)} Campaign${campaigns ? ` #${campaigns.length + 1}` : ""}`;

  let validationSchema: CampaignSettingsSchemaType =
    DefaultCampaignSettingsSchema;
  if (type === "search") {
    validationSchema = SearchCampaignSettingsSchema;
  } else if (type === "drip") {
    validationSchema = DripCampaignSettingsSchema;
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: defaultName,
      size: type === "list" ? 10000 : 250,
    },
    resolver: valibotResolver(validationSchema),
  });

  const Footer = isTabletOrDesktop ? DialogFooter : DrawerFooter;

  return (
    <>
      <h3 className="mb-8 text-center text-headline-2xl-bold md:text-headline-xl-bold">
        Create a new <span className="capitalize">{type}</span> campaign
      </h3>

      <Label htmlFor="campaignName" size="sm" className="mb-1">
        Campaign name
      </Label>

      <MaxLengthInput
        variant="lg"
        id="campaignName"
        placeholder="Campaign name"
        className="mb-5"
        type="text"
        error={errors.name?.message}
        characterLength={watch("name").length}
        maxLength={getSchemaMaxLength(CampaignNameSchema.entries.name)}
        {...register("name")}
      />

      <div className="mb-20 flex flex-col divide-y divide-black-200 md:mb-4">
        <LinkedInAccountSelect
          accounts={loggedInAccounts}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
        />

        <OutreachType
          outreachType={selectedOutreachType}
          setOutreachType={setSelectedOutreachType}
          campaignType={type}
        />

        {type !== "list" && (
          <AudienceSize
            campaignType={type}
            errorMessage={errors.size?.message}
            register={register}
          />
        )}
      </div>

      <Footer>
        <Button
          onClick={goBack}
          size={isTabletOrDesktop ? "md" : "lg"}
          variant="secondary-black"
          disabled={isCreatingCampaign}
        >
          Back
        </Button>

        <Button
          onClick={handleSubmit(onSubmit)}
          size={isTabletOrDesktop ? "md" : "lg"}
          isLoading={isCreatingCampaign}
        >
          Create{isTabletOrDesktop ? " campaign" : ""}
        </Button>
      </Footer>
    </>
  );
}
