import { useMemo } from "react";

import { FacetParam, SelectedFacetParam } from "common/types";

// sort selected facets to the top of the list
export default function useSortedFacets(
  allFacets: FacetParam[],
  selectedFacets: SelectedFacetParam[],
) {
  return useMemo(() => {
    if (allFacets.length) {
      const facets = [...allFacets];
      [...selectedFacets].reverse().forEach((facet) => {
        // remove selected facet by index
        const removalIndex = facets.findIndex(({ id }) =>
          Array.isArray(facet.id) && Array.isArray(id)
            ? facet.id.every((string) => id.includes(string))
            : facet.id === id,
        );

        const [removedFacet] = facets.splice(removalIndex, 1);

        // add to beginning of the list
        facets.unshift(removedFacet);
      });
      return facets;
    }

    return selectedFacets.map((facet) => ({ ...facet, target_count: 0 }));
  }, [allFacets, selectedFacets]);
}
