import React from "react";
import { PlusCircle, Question } from "@phosphor-icons/react";
import { useIntercom } from "react-use-intercom";

import useCampaigns from "common/datahooks/useCampaigns";
import { Button } from "common/components/ui/Button";
import RestrictedComponent from "common/components/RestrictedComponent";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import SearchInput from "common/components/SearchInput";
import NoCampaignsPlaceholder from "common/components/NoCampaignsPlaceholder";
import WorkspaceAccountSelector from "common/components/WorkspaceAccountSelector";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import useAppStore from "common/hooks/useAppStore";

import StateFilter from "./StateFilter";
import CampaignsTable from "./CampaignsTable";
import MobileFilters from "./MobileFilters";
import useCampaignsTable from "../../hooks/useCampaignsTable";

import NoResultsImage from "assets/images/empty-placeholders/result-not-found.png";

export default function CampaignsList() {
  const isDesktop = useTwBreakpoint("lg");
  const { showNewMessage } = useIntercom();

  const setIsCampaignModalOpen = useAppStore(
    (state) => state.setIsCampaignModalOpen,
  );
  const { accounts } = useSelectedWorkspaceContext();

  const isEveryAccountLoggedOut = accounts.every(
    ({ state }) => state !== "LoggedIn",
  );

  const { campaigns, isLoadingCampaigns, campaignsError, refetchCampaigns } =
    useCampaigns();

  const {
    filteredCampaigns,
    hasFilters,
    searchText,
    setSearchText,
    campaignStates,
    setCampaignStates,
    clearFilters,
    selectedAccountIds,
    setSelectedAccountIds,
  } = useCampaignsTable(campaigns);

  if (campaignsError) {
    return (
      <ErrorPlaceholder
        errorMessage="Could not display Campaigns"
        onRetry={refetchCampaigns}
      />
    );
  }

  if (!isLoadingCampaigns && (!campaigns || !campaigns.length)) {
    return <NoCampaignsPlaceholder />;
  }

  const currentPageCampaigns = filteredCampaigns.length
    ? filteredCampaigns
    : new Array(7).fill({});

  return (
    <>
      {/* Top Section */}
      <section className="flex flex-col items-center justify-between gap-4 lg:flex-row-reverse">
        <div className="grid items-center gap-x-2 max-lg:w-full max-lg:grid-cols-2 lg:flex">
          <Button
            leftIcon={<Question />}
            variant="secondary-black"
            onClick={() =>
              showNewMessage(
                "I would like some help with my targeting. Can you tell me more about DFY targeting?",
              )
            }
          >
            <span className="truncate">DFY Targeting</span>
          </Button>

          {/* User can't create a new campaign if all LinkedIn accounts are logged out */}
          {isEveryAccountLoggedOut ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  data-intercom-target="create-campaign-button"
                  leftIcon={<PlusCircle weight="fill" />}
                  disabled
                >
                  <span className="truncate">Create campaign</span>
                </Button>
              </TooltipTrigger>

              <TooltipContent>
                You must have at least one connected LinkedIn account to create
                a campaign
              </TooltipContent>
            </Tooltip>
          ) : (
            <RestrictedComponent
              disabledForRoles={["member"]}
              hiddenForRoles={[]}
            >
              {(isDisabled) => (
                <Button
                  data-intercom-target="create-campaign-button"
                  onClick={() => setIsCampaignModalOpen(true)}
                  leftIcon={<PlusCircle weight="fill" />}
                  disabled={isDisabled}
                >
                  <span className="truncate">Create campaign</span>
                </Button>
              )}
            </RestrictedComponent>
          )}
        </div>

        <div className="flex w-full items-center gap-2">
          <SearchInput
            className="max-lg:flex-1 lg:w-full lg:max-w-80"
            placeholder="Search campaign"
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            onClear={() => setSearchText("")}
          />

          {isDesktop ? (
            <>
              <StateFilter
                selectedFilters={campaignStates}
                setFilters={setCampaignStates}
                campaigns={campaigns}
              />

              <WorkspaceAccountSelector
                selectedAccountIds={selectedAccountIds}
                setSelectedAccountIds={setSelectedAccountIds}
              />
            </>
          ) : (
            <MobileFilters
              campaigns={campaigns}
              currentFilters={{ selectedAccountIds, campaignStates }}
              clearFilters={clearFilters}
              applyFilters={(newFilters) => {
                setCampaignStates(newFilters.campaignStates);
                setSelectedAccountIds(newFilters.selectedAccountIds);
              }}
            />
          )}
        </div>
      </section>

      {hasFilters && !filteredCampaigns.length ? (
        <EmptyPlaceholder
          title="No results found"
          subtitle="There were no results that match your criteria"
          imageSrc={NoResultsImage}
          actionComponent={<Button onClick={clearFilters}>Clear Search</Button>}
        />
      ) : (
        <CampaignsTable
          isLoading={isLoadingCampaigns}
          campaigns={currentPageCampaigns}
        />
      )}
    </>
  );
}
