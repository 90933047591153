import React, { useState } from "react";
import { Plus } from "@phosphor-icons/react";

import usePrimaryPaymentSource from "common/datahooks/usePrimaryPaymentSource";
import { Button } from "common/components/ui/Button";
import { PaymentSource } from "common/types";

import PaymentSourceOption from "./PaymentSourceOption";

interface PaymentMethodSelectionProps {
  paymentSources: PaymentSource[];
  selectedPaymentSource: PaymentSource;
  addPaymentMethod: () => void;
  onBack: () => void;
  onSelect?: (paymentSource: PaymentSource) => void;
}

export default function PaymentMethodSelection({
  paymentSources,
  selectedPaymentSource,
  addPaymentMethod,
  onBack,
  onSelect = null,
}: PaymentMethodSelectionProps) {
  const { isSettingPrimaryPaymentSource, setPrimaryPaymentSource } =
    usePrimaryPaymentSource();

  // Local state for tracking the currently selected payment method
  const [selectedMethod, setSelectedMethod] = useState<PaymentSource>(
    selectedPaymentSource,
  );

  // Function for selecting the payment method
  function onPaymentMethodSelect(paymentSource: PaymentSource): void {
    if (onSelect) {
      onSelect(paymentSource);
    } else {
      setPrimaryPaymentSource({ paymentSource });
    }

    // Close the modal
    onBack();
  }

  return (
    <>
      <h3 className="mb-2 text-center text-headline-2xl-bold">
        Select your preferred payment method
      </h3>

      <span className="mb-8 text-center text-black-500">
        Set a preferred payment method for your Aimfox subscription. You can
        change this at any time
      </span>

      <div className="mb-8 flex flex-col gap-2">
        <div className="flex max-h-[310px] flex-col gap-y-2 overflow-y-auto scrollbar-thin">
          {/* Payment methods */}
          {paymentSources.map((paymentSource) => (
            <PaymentSourceOption
              key={paymentSource.id}
              paymentSource={paymentSource}
              selectedMethod={selectedMethod}
              setSelectedMethod={setSelectedMethod}
            />
          ))}
        </div>

        <button
          type="button"
          className="flex items-center justify-start gap-2 rounded-2xl border border-dashed border-black-300 px-4 py-2"
          onClick={addPaymentMethod}
        >
          <div className="rounded-full bg-black-900 p-2">
            <Plus color="white" size={20} />
          </div>

          <span className="text-body-14-bold">Add payment method</span>
        </button>
      </div>

      <div className="flex flex-row items-center justify-between">
        <Button size="lg" variant="secondary-black" onClick={onBack}>
          Back
        </Button>

        <Button
          size="lg"
          variant="primary-black"
          onClick={() => onPaymentMethodSelect(selectedMethod)}
          isLoading={isSettingPrimaryPaymentSource}
        >
          Confirm
        </Button>
      </div>
    </>
  );
}
