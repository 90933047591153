import { createContext, useContext } from "react";

import { DripFilters } from "../../../../types";

const DripFiltersContext = createContext<{
  filters: DripFilters;
  setFilters: (filters: Partial<DripFilters>) => void;
}>(null);

export const DripFiltersProvider = DripFiltersContext.Provider;

export function useDripFiltersContext(): {
  filters: DripFilters;
  setFilters: (filters: Partial<DripFilters>) => void;
} {
  return useContext(DripFiltersContext);
}
