import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { Button } from "common/components/ui/Button";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";

import ConversationItem from "./ConversationItem";
import { Conversation } from "../../types";
import ConversationLoader from "./ConversationLoader";

import NoResultsImage from "assets/images/empty-placeholders/messages.png";

interface ConversationListProps {
  isLoadingConversations: boolean;
  conversations: Conversation[];
  activeConversation: Conversation;
  hasMoreConversations: boolean;
  keywords: string;
  setActiveConversationUrn: (
    conversationUrn: string,
    accountId: string,
  ) => void;
  loadMoreConversations: () => void;
  clearKeywords: () => void;
  selectedFilters: string[];
  clearFilters: () => void;
}

export default function ConversationsList({
  loadMoreConversations,
  isLoadingConversations,
  conversations,
  activeConversation,
  setActiveConversationUrn,
  hasMoreConversations,
  keywords,
  clearKeywords,
  selectedFilters,
  clearFilters,
}: ConversationListProps) {
  if (isLoadingConversations) {
    return <ConversationLoader itemsCount={7} />;
  }

  if (!conversations.length) {
    if (keywords || selectedFilters) {
      let emptyPlaceholderSubtitle = `No results found for "${keywords}"`;

      if (selectedFilters && !keywords) {
        emptyPlaceholderSubtitle = "No results found for selected filters";
      }

      return (
        <EmptyPlaceholder
          imageSrc={NoResultsImage}
          title="No results found"
          subtitle={emptyPlaceholderSubtitle}
          actionComponent={
            <Button
              onClick={() => {
                if (selectedFilters.length > 0) {
                  clearFilters();
                  return;
                }
                clearKeywords();
              }}
            >
              Clear {selectedFilters.length > 0 ? "all filters" : "search"}
            </Button>
          }
        />
      );
    }

    return (
      <p className="py-6 text-center text-headline-xl-regular text-black-400">
        No conversations
      </p>
    );
  }

  return (
    <InfiniteScroll
      className="flex flex-col"
      next={loadMoreConversations}
      hasMore={hasMoreConversations}
      dataLength={conversations.length}
      loader={<ConversationLoader itemsCount={3} />}
      scrollableTarget="thread-scroll-container"
    >
      {conversations?.map((conversation, index) => {
        const {
          conversation_urn: activeConversationUrn,
          owner: activeAccountId,
        } = activeConversation || {};

        const { conversation_urn: conversationUrn, owner: accountId } =
          conversation;

        const isSelected =
          activeConversationUrn === conversationUrn &&
          activeAccountId === accountId;

        const isPreviousSelected =
          index >= 0 &&
          activeConversationUrn ===
            conversations[index + 1]?.conversation_urn &&
          activeAccountId === conversations[index + 1]?.owner;

        const conversationItemKey = `${conversationUrn} + ${index}`;

        return (
          <ConversationItem
            key={conversationItemKey}
            isSelected={isSelected}
            isPreviousSelected={isPreviousSelected}
            conversation={conversation}
            onSelect={() =>
              setActiveConversationUrn(conversationUrn, accountId)
            }
            isLoading={isLoadingConversations}
          />
        );
      })}
    </InfiniteScroll>
  );
}
