import React, { useCallback, useState } from "react";

import { typedObjectEntries } from "common/helpers/utils";
import { ProfileSearchParams } from "common/types";
import { Tag, TagVariantType } from "common/components/ui/Tag";
import Skeleton from "common/components/ui/Skeleton";
import RenderIf from "common/components/RenderIf";

import {
  keywordModifierOptions,
  keywordOptions,
  profileSearchParamsIcons,
} from "../../constants";

interface SearchTargetingLabelsProps {
  searchParams: ProfileSearchParams;
  isLoading?: boolean;
}

function getTargetingLabels(targeting: ProfileSearchParams) {
  return typedObjectEntries(targeting)
    .filter(([, values]) => !!values)
    .flatMap(([key, values]) => {
      const Icon = profileSearchParamsIcons[key];
      const items = Array.isArray(values) ? values : [values];

      return items.map((value) => {
        let text = "";
        if ("name" in value) {
          text = value.name;
        } else if ("full_name" in value) {
          text = value.full_name;
        } else if ("text" in value) {
          text = value.text;
        }

        const isKeyword = "text" in value;
        const tagVariant: TagVariantType = isKeyword
          ? keywordModifierOptions[value.modifier].tagVariant
          : "primary-black";

        let leftIcon = <Icon weight="fill" />;

        if (isKeyword) {
          const { icon: KeywordIcon } = keywordOptions[value.type];

          leftIcon = (
            <KeywordIcon
              weight={value.type === "general" ? "regular" : "fill"}
            />
          );
        }

        return (
          <Tag
            key={isKeyword ? value.text : value.urn}
            variant={tagVariant}
            size="lg"
            leftIcon={leftIcon}
          >
            {text}
          </Tag>
        );
      });
    });
}

export default function SearchTargetingLabels({
  searchParams,
  isLoading = false,
}: SearchTargetingLabelsProps) {
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [showMoreIndex, setShowMoreIndex] = useState(0);

  const measuredRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      const { children, clientWidth } = node;

      let tagsRowWidth = 0;

      for (let i = 0; i < children.length; i += 1) {
        const childWidth = children[i].clientWidth + 6; // +6 for gap

        if (tagsRowWidth + childWidth > clientWidth) {
          setShowMoreIndex(i);
          break;
        }

        tagsRowWidth += childWidth;
      }
    }
  }, []);

  if (isLoading) {
    return (
      <div className="flex flex-wrap gap-1.5">
        <Skeleton className="h-7 w-32" />
        <Skeleton className="h-7 w-32" />
      </div>
    );
  }

  const labels = getTargetingLabels(searchParams);

  const visibleLabels =
    isShowingMore || showMoreIndex === 0
      ? labels
      : labels.slice(0, showMoreIndex);

  return (
    <div ref={measuredRef} className="flex w-full flex-wrap gap-1.5">
      {visibleLabels}
      <RenderIf condition={showMoreIndex > 0}>
        <Tag
          size="lg"
          variant="quaternary"
          className="transition-colors hover:bg-black-200"
          asChild
        >
          <button
            type="button"
            onClick={() => setIsShowingMore((prev) => !prev)}
          >
            Show {isShowingMore ? "less" : "more"}
          </button>
        </Tag>
      </RenderIf>
    </div>
  );
}
