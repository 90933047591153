import { useMutation } from "@tanstack/react-query";

import { post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { LeadsSearchParams, SelectedFacetParam } from "common/types";

import { parseSearchParams } from "../utils";

async function exportLeadsRequest(
  workspaceId: string,
  searchParams: LeadsSearchParams<SelectedFacetParam>,
): Promise<string> {
  const { url } = await post(
    `workspaces/${workspaceId}/leads-export`,
    parseSearchParams(searchParams),
  );
  return url;
}
export default function useLeadMutations() {
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { mutateAsync: exportLeads, isPending: isExportingLeads } = useMutation(
    {
      mutationFn: ({
        searchParams,
      }: {
        searchParams: LeadsSearchParams<SelectedFacetParam>;
      }) => exportLeadsRequest(workspaceId, searchParams),
    },
  );

  return {
    exportLeads,
    isExportingLeads,
  };
}
