import React from "react";
import { Info, type Icon } from "@phosphor-icons/react";

import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "common/components/ui/Tooltip";
import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

interface ItemContainerProps {
  icon: Icon;
  title: string;
  description: string;
  children: React.ReactNode;
  infoText?: string;
}

export default function ItemContainer({
  icon: Icon,
  title,
  description,
  children,
  infoText = "",
}: ItemContainerProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  return (
    <section className="flex flex-col justify-between gap-3 py-5 md:flex-row md:items-center">
      <div className="flex gap-x-3">
        <Button variant="secondary-black" size="lg" intent="labelIcon">
          <Icon />
        </Button>
        <div className="flex flex-col gap-0.5">
          <div className="flex gap-1">
            <h5 className="text-button-16">{title}</h5>
            <RenderIf condition={infoText && isTabletOrDesktop}>
              <Tooltip>
                <TooltipTrigger>
                  <Info size={18} weight="fill" className="fill-black-300" />
                </TooltipTrigger>
                <TooltipContent>{infoText}</TooltipContent>
              </Tooltip>
            </RenderIf>
          </div>
          <p className="text-caption-12-regular text-black-500">
            {description}
          </p>
        </div>
      </div>

      {children}
    </section>
  );
}
