import React, { MouseEvent, useEffect, useState } from "react";
import { ArrowsClockwise } from "@phosphor-icons/react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "common/components/ui/Accordion";
import ProfileImage from "common/components/ui/ProfileImage";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import RenderIf from "common/components/RenderIf";
import { Tag } from "common/components/ui/Tag";
import { Button } from "common/components/ui/Button";
import useWorkspaceLabels from "common/datahooks/useWorkspaceLabels";
import LabelTag from "common/components/LabelTag";
import Switch from "common/components/ui/Switch";
import useMergedFacets from "common/hooks/useMergedFacets";
import { Facets, LeadsSearchParams, SelectedFacetParam } from "common/types";

import SearchFacet from "./SearchFacet";
import useLeadsSearchParams from "../../hooks/useLeadsSearchParams";
import { leadsFacets } from "../../constants";

export default function SearchFilters({
  facets,
  accountsSync,
  isLoading,
  setSearchParams,
  searchParams,
}: {
  facets: Facets;
  accountsSync: Record<string, boolean>;
  isLoading: boolean;
  searchParams: LeadsSearchParams<SelectedFacetParam>;
  setSearchParams: (
    searchParams: Partial<LeadsSearchParams<SelectedFacetParam>>,
  ) => void;
}) {
  const { accounts } = useSelectedWorkspaceContext();
  const { workspaceLabels } = useWorkspaceLabels();
  const [accordionValue, setAccordionValue] = useState(
    // tests don't work because all the accordions are closed by default. This opens all the accordions in test environment.
    import.meta.env.MODE === "test"
      ? leadsFacets.map(({ label }) => label)
      : [leadsFacets[0].label],
  );
  const { toggleSearchParam, resetSearchParam, setSearchParam } =
    useLeadsSearchParams(searchParams, setSearchParams);

  useEffect(() => {
    setSearchParams(searchParams);
  }, [searchParams]);

  const mergedFacets = useMergedFacets(facets);

  function onAccordionTriggerClick(
    e: MouseEvent<HTMLButtonElement>,
    value: string,
  ) {
    if (
      !e.nativeEvent
        .composedPath()
        .some((element) => (element as Element).tagName === "BUTTON")
    ) {
      setAccordionValue(
        accordionValue.includes(value)
          ? accordionValue.filter((item) => item !== value)
          : [...accordionValue, value],
      );
    }
  }

  return (
    <>
      <div className="flex items-center justify-between border-b border-b-black-200 px-2 py-4">
        <span className="text-button-14">Favorite</span>
        <Switch
          checked={searchParams.favorite}
          onCheckedChange={(isChecked) => setSearchParam("favorite", isChecked)}
          disabled={isLoading}
        />
      </div>
      <Accordion type="multiple" value={accordionValue}>
        {leadsFacets.map(({ searchKey, facetKey, label: facetLabel }) => (
          <AccordionItem value={facetLabel} key={facetLabel}>
            <AccordionTrigger
              className="h-11"
              onClick={(e) => onAccordionTriggerClick(e, facetLabel)}
            >
              <span className="text-button-14">{facetLabel}</span>

              {!!searchParams[searchKey].length && (
                <>
                  <Tag variant="primary-black">
                    {searchParams[searchKey].length}
                  </Tag>

                  <Button
                    className="ml-auto"
                    size="sm"
                    variant="quaternary-danger"
                    onClick={() => resetSearchParam(searchKey)}
                  >
                    Clear all
                  </Button>
                </>
              )}
            </AccordionTrigger>
            <AccordionContent className="flex flex-col">
              <SearchFacet
                label={facetLabel}
                allFacets={
                  mergedFacets ? mergedFacets[facetKey || searchKey] : []
                }
                selectedFacets={searchParams[searchKey]}
                toggleSearchParam={(param) =>
                  toggleSearchParam(searchKey, param)
                }
                isLoading={isLoading}
                renderFacet={(param) => {
                  if (searchKey === "labels") {
                    const label = workspaceLabels.find(
                      ({ id }) => id === param.id,
                    );

                    return <LabelTag key={label.id} label={label} />;
                  }
                  if (searchKey === "lead_of") {
                    const account = accounts.find(
                      ({ id }) => id.toString() === param.name,
                    );
                    return (
                      <>
                        <ProfileImage
                          src={account.picture_url}
                          size="xs"
                          className="rounded-full"
                        />

                        <span className="text-start text-button-14">
                          {account.full_name}
                        </span>
                        <RenderIf
                          condition={accountsSync && accountsSync[account.id]}
                        >
                          <div className="ml-1.5 rounded-full bg-purple-600 p-0.5">
                            <ArrowsClockwise className="size-2 animate-spin text-white" />
                          </div>
                        </RenderIf>
                      </>
                    );
                  }
                  return (
                    <span className="truncate text-start text-button-14">
                      {param.name}
                    </span>
                  );
                }}
              />
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
}
