import { FacetParam, Facets, LeadsSearchParams } from "common/types";

export const defaultLeadsSearchParams: LeadsSearchParams<FacetParam> = {
  keywords: "",
  favorite: false,
  origins: [],
  past_companies: [],
  current_companies: [],
  locations: [],
  labels: [],
  interests: [],
  skills: [],
  education: [],
  languages: [],
  lead_of: [],
};
export const leadsFacets: {
  label: string;
  searchKey: keyof Omit<LeadsSearchParams<never>, "keywords" | "favorite">;
  facetKey?: keyof Facets;
}[] = [
  { label: "Accounts", searchKey: "lead_of" },
  {
    label: "Origins",
    searchKey: "origins",
  },
  { label: "Current companies", searchKey: "current_companies" },
  {
    label: "Past companies",
    searchKey: "past_companies",
  },
  {
    label: "Locations",
    searchKey: "locations",
  },
  {
    label: "Labels",
    searchKey: "labels",
  },
  {
    label: "Education",
    searchKey: "education",
    facetKey: "schools",
  },
  {
    label: "Skills",
    searchKey: "skills",
  },
  {
    label: "Interests",
    searchKey: "interests",
  },
  {
    label: "Languages",
    searchKey: "languages",
  },
];
