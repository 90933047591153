import React from "react";
import { UsersThree } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { type UseFormRegister } from "react-hook-form";

import Label from "common/components/ui/Label";
import { Input } from "common/components/ui/Input";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { CampaignType } from "common/types";
import RenderIf from "common/components/RenderIf";

import ItemContainer from "./ItemContainer";

interface AudienceSizeProps {
  campaignType: CampaignType;
  errorMessage: string;
  register: UseFormRegister<{
    name: string;
    size: number;
  }>;
}

export default function AudienceSize({
  campaignType,
  errorMessage,
  register,
}: AudienceSizeProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const isDrip = campaignType === "drip";

  const { t } = useTranslation("campaigns", {
    keyPrefix: "audienceSize",
  });

  return (
    <ItemContainer
      icon={UsersThree}
      title={t(isDrip ? "dripTitle" : "title")}
      description={t(isDrip ? "dripDescription" : "description")}
      infoText={
        isDrip
          ? t("dripInfoText")
          : t("infoText", {
              limit: campaignType === "search" ? 1000 : 10000,
            })
      }
    >
      <RenderIf condition={campaignType !== "drip"}>
        <div className="flex flex-col gap-1 max-md:ml-[60px]">
          <Label htmlFor="audienceSize" size="sm">
            Audience
          </Label>

          <Input
            variant={isTabletOrDesktop ? "md" : "lg"}
            className="max-w-32"
            id="audienceSize"
            name="audienceSize"
            error={errorMessage}
            leftComponent={<UsersThree weight="fill" />}
            {...register("size", {
              valueAsNumber: true,
            })}
          />
        </div>
      </RenderIf>
    </ItemContainer>
  );
}
