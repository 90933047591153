import React from "react";
import { Drop } from "@phosphor-icons/react";

import { RecentLead } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";
import RenderIf from "common/components/RenderIf";

interface RecentLeadsItemProps {
  lead: RecentLead;
  showFlowOrigin: boolean;
  showCampaignOrigin: boolean;
  onLeadSelect: () => void;
}

export default function RecentLeadsItem({
  lead,
  showFlowOrigin,
  showCampaignOrigin,
  onLeadSelect,
}: RecentLeadsItemProps) {
  const {
    campaign_name: campaignName,
    target: { picture_url: pictureUrl, full_name: fullName, occupation },
    flow_name: flowName,
    is_drip: isDrip,
  } = lead;

  return (
    <button
      className="flex items-center gap-x-3 rounded-2xl border border-black-200 bg-white px-4 py-3 transition-colors hover:bg-black-100"
      onClick={onLeadSelect}
      type="button"
    >
      <ProfileImage src={pictureUrl} />

      <div className="flex flex-col gap-y-0.5">
        <div className="flex items-center gap-x-0.5">
          <span className="line-clamp-1 break-all text-start text-button-14">
            {fullName}
          </span>

          <RenderIf condition={isDrip}>
            <Drop weight="fill" size={16} className="fill-blue-300" />
          </RenderIf>
        </div>

        <p className="line-clamp-1 break-all text-start text-caption-12-regular text-black-500">
          {showFlowOrigin || showCampaignOrigin ? (
            <>
              Origin:{" "}
              <span className="font-semibold">
                {showFlowOrigin ? flowName : campaignName}
              </span>
            </>
          ) : (
            occupation
          )}
        </p>
      </div>
    </button>
  );
}
