import React from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import useMergedFacets from "common/hooks/useMergedFacets";
import useWorkspaceLabels from "common/datahooks/useWorkspaceLabels";
import { Facets } from "common/types";

import DesktopFilters from "./DesktopFilters";
import MobileFilters from "./MobileFilters";

interface FiltersProps {
  facets: Facets;
  isLoading: boolean;
}

export default function Filters({ facets, isLoading }: FiltersProps) {
  const isDesktop = useTwBreakpoint("lg");
  const mergedFacets = useMergedFacets(facets);
  const { workspaceLabels } = useWorkspaceLabels();

  const Component = isDesktop ? DesktopFilters : MobileFilters;
  return (
    <Component
      facets={mergedFacets}
      isLoading={isLoading}
      labels={workspaceLabels}
    />
  );
}
