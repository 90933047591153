import React from "react";

import { GenericSearchParam, SearchParamWithImage } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";
import Checkbox from "common/components/ui/Checkbox";

interface SearchParamOptionProps {
  option: GenericSearchParam | SearchParamWithImage;
  isSelected: boolean;
  onClick: () => void;
}

export default function SearchParamOption({
  option,
  isSelected,
  onClick,
}: SearchParamOptionProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="flex w-full items-center gap-x-1.5 bg-white px-4 py-2 disabled:opacity-30 lg:transition-colors lg:hover:bg-black-50"
    >
      <Checkbox checked={isSelected} isStyleOnly />
      {"logo_url" in option ? (
        <>
          <ProfileImage src={option.logo_url} size="sm" />
          <div>
            <h5 className="truncate text-button-14">{option.name}</h5>
            <p className="text-black-400">{option.description}</p>
          </div>
        </>
      ) : (
        <span className="truncate text-left text-button-14">{option.name}</span>
      )}
    </button>
  );
}
