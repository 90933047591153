import React from "react";
import {
  LinkSimple,
  Chat,
  Envelope,
  MegaphoneSimple,
  CheckCircle,
  XCircle,
} from "@phosphor-icons/react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import Switcher from "common/components/ui/Switcher";
import { CampaignOutreachType, CampaignType } from "common/types";

import ItemContainer from "./ItemContainer";

const baseMethodOptions = [
  {
    value: "connect",
    label: "Connect",
    icon: <LinkSimple />,
  },
  {
    value: "inmail",
    label: "InMail",
    icon: <Envelope />,
  },
  {
    value: "message",
    label: "Message",
    icon: <Chat />,
  },
] as const;

interface OutreachTypeProps {
  campaignType: CampaignType;
  outreachType: CampaignOutreachType;
  setOutreachType: (newType: CampaignOutreachType) => void;
}

export default function OutreachType({
  campaignType,
  outreachType,
  setOutreachType,
}: OutreachTypeProps) {
  const isDrip = campaignType === "drip";
  const isGroupOrEvent = campaignType === "group" || campaignType === "event";

  const outreachTypeOptions = baseMethodOptions.filter((option) =>
    isGroupOrEvent ? true : option.value !== "message",
  );

  const { t } = useTranslation("campaigns", {
    keyPrefix: "outreachType",
  });

  return (
    <ItemContainer
      icon={MegaphoneSimple}
      title={t("title")}
      description={t("description")}
      infoText={t(isDrip ? "dripInfoText" : "infoText")}
    >
      {isDrip ? (
        <span className="pointer-events-none inline-flex h-10 w-fit items-center gap-x-2 rounded-full bg-whiteGray pl-3 pr-6 text-button-14 text-black-900 max-md:ml-[60px]">
          <Chat size={20} /> Message
        </span>
      ) : (
        <div className="flex flex-col gap-2 max-md:ml-[60px] md:items-center">
          <Switcher
            size="sm"
            options={outreachTypeOptions}
            selectedOption={outreachType}
            onOptionSelect={(option) =>
              setOutreachType(option as CampaignOutreachType)
            }
          />
          <div className="flex items-center gap-2">
            {outreachTypeOptions.map(({ label, value }) => {
              const isMethodEnabled =
                (outreachType === "inmail" && value === "inmail") ||
                (outreachType === "connect" && value !== "message") ||
                (outreachType === "message" && value !== "connect");

              return (
                <div key={value} className="flex items-center gap-x-0.5">
                  {isMethodEnabled ? (
                    <CheckCircle
                      className="fill-green-500"
                      size={16}
                      weight="fill"
                    />
                  ) : (
                    <XCircle
                      className="fill-black-400"
                      size={16}
                      weight="fill"
                    />
                  )}

                  <span
                    className={clsx(
                      "text-caption-10-regular transition-colors",
                      !isMethodEnabled && "text-black-400",
                    )}
                  >
                    {label}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </ItemContainer>
  );
}
